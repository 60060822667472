var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tableBox" }, [
    _c(
      "div",
      { staticClass: "table" },
      [
        _c(
          "el-table",
          {
            ref: "dragTable",
            staticClass: "table",
            attrs: {
              data: _vm.tableData,
              "row-key": "lineId",
              border: "",
              "max-height": "300",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                label: "序号",
                type: "index",
                width: "80",
                "class-name": "allowDrag",
                align: "center",
              },
            }),
            !_vm.disabled
              ? _c("el-table-column", {
                  attrs: { align: "center", label: "操作", width: "120" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.isAddTableRowBtn
                              ? _c("i", {
                                  staticClass:
                                    "el-icon-circle-plus operatePush",
                                  on: { click: _vm.tableAddRow },
                                })
                              : _vm._e(),
                            _c("i", {
                              staticClass: "el-icon-remove operateDel",
                              on: {
                                click: function ($event) {
                                  return _vm.tableDelRow(
                                    scope.row,
                                    scope.$index
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    321303162
                  ),
                })
              : _vm._e(),
            _c("el-table-column", {
              attrs: {
                label: "类型",
                align: "center",
                prop: "lineType",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("dict-tag", {
                        attrs: {
                          options:
                            _vm.dict.type
                              .vip_applicable_commodities_appoint_type,
                          value: scope.row.lineType,
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "编码",
                width: "260",
                align: "center",
                prop: "lineNo",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "名称",
                align: "center",
                prop: "lineName",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._t("amount"),
            _c("el-table-column", {
              attrs: { label: "单位", align: "center", width: "120" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return scope.row.lineType == 1
                        ? [
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { disabled: _vm.disabled },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeUnitPrice(
                                          scope.row,
                                          scope.row.unitId,
                                          scope.row.units
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.unitId,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "unitId", $$v)
                                      },
                                      expression: "scope.row.unitId",
                                    },
                                  },
                                  _vm._l(scope.row.units, function (item) {
                                    return _c("el-option", {
                                      key: item.unitId,
                                      attrs: {
                                        label: item.unitName,
                                        value: item.unitId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c("el-table-column", {
              attrs: {
                label: "规格",
                align: "center",
                width: "200",
                prop: "goodsSpec",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "售价(元)",
                align: "center",
                width: "200",
                prop: "salePrice",
              },
            }),
            _vm._t("discountPrice"),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }