<template>
  <div>
    <topOperatingButton
      :disabled="disabledBtn"
      :showAudit="false"
      :isAuditBillBtn="false"
      :isExamineBtn="true"
      @submitForm="submitForm(false)"
      @addBill="submitForm(true)"
      @auditBill="submitAudit"
      @handleAdd="addScheme"
      @getQuit="quitScheme"
      id="topOperatingButton"
    >
    </topOperatingButton>
    <!-- <el-button
                    type="primary"
                    size="mini"
                    @click="submitForm(false)"
                    :disabled="disabledBtn"
                >保存
                </el-button
                >
                <el-button
                    type="primary"
                    size="mini"
                    @click="submitForm(true)"
                    :disabled="disabledBtn"
                >保存并新增
                </el-button
                >
                <el-dropdown
                    split-button
                    size="mini"
                    @click="submitAudit('2')"
                    @command="submitAudit('0')"
                >
                    审核
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>反审核</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-button type="primary" size="mini" v-if="disabled" @click="addScheme"
                >新增
                </el-button
                >
                <el-button size="mini" @click="quitScheme">退出</el-button> -->
    <div
      class="special-scheme-detail"
      v-loading="boxLoading"
      element-loading-text="数据加载中"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <!-- 基本信息： 所有方案共用 -->
        <cardTitleCom cardTitle="基本信息" :icon="form.billStatus">
          <template slot="cardContent">
            <div class="basicInfo">
              <div class="scheme x-f">
                <el-form-item label="方案编号" prop="billNo">
                  <el-input
                    v-model="form.billNo"
                    disabled
                    size="mini"
                    placeholder="自动生成"
                  ></el-input>
                </el-form-item>
                <el-form-item label="方案名称" prop="promoName">
                  <el-input
                    size="mini"
                    v-model="form.promoName"
                    :disabled="disabled"
                    :maxlength="40"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="scheme x-f">
                <el-form-item label="促销日期" prop="promoDate">
                  <el-date-picker
                    size="mini"
                    v-model="form.promoDate"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="forbiddenTime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                    :disabled="disabled"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  label="适用渠道"
                  prop="AllowOfflineShop"
                  v-if="form.promoType != 12"
                >
                  <el-checkbox-group v-model="form.AllowOfflineShop">
                    <el-checkbox label="0" :disabled="disabled">线下门店 </el-checkbox>
                    <el-checkbox label="1" :disabled="disabled">线上商城 </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
            </div>
          </template>
        </cardTitleCom>
        <!-- 活动规则： 所有方案共用-->
        <cardTitleCom cardTitle="活动规则">
          <template slot="cardContent">
            <div class="activity">
              <el-form-item
                label="折上折"
                prop="shopRangeType"
                v-if="form.promoType != 9 && form.promoType != 12"
              >
                <el-checkbox v-model="form.isAllowTempReagio" :disabled="disabled"
                  >支持临时改价/折扣折上折</el-checkbox
                >
                <el-checkbox v-model="form.isAllowVipReagio" :disabled="disabled"
                  >支持会员折上折</el-checkbox
                >
                <el-checkbox v-model="form.isAllowCouponReagio" :disabled="disabled"
                  >支持优惠券折上折</el-checkbox
                >
              </el-form-item>
              <el-form-item label="适用门店" prop="shopRangeType">
                <el-radio-group v-model="form.shopRangeType" :disabled="disabled">
                  <el-radio
                    :label="dict.value"
                    v-for="dict in dict.type.fill_shop_range_type"
                    :key="dict.value"
                    :value="dict.value"
                    >{{ dict.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 指定门店区域 -->
              <div
                class="selectShop"
                v-if="form.shopRangeType === '1' || form.shopRangeType === '2'"
              >
                <div class="shopBtn">
                  <el-button size="mini" @click="openSelectShop()" :disabled="disabled"
                    >选择门店
                  </el-button>
                </div>
                <!-- 门店表格 -->
                <comTable
                  class="marB10"
                  :columnLabel="shopTableLable"
                  :tableData="form.shopItem"
                  @delTableRow="delTableRow($event, 'shop')"
                  :disabled="disabled"
                ></comTable>
              </div>
              <div class="x-x" style="height: 36px; margin-bottom: 10px">
                <el-form-item label="适用对象" prop="vipLevelRangeTypeArr">
                  <el-checkbox-group
                    v-model="form.vipLevelRangeTypeArr"
                    @change="handleCheckVip"
                    :disabled="disabled"
                  >
                    <el-checkbox label="0">全部会员</el-checkbox>
                    <el-checkbox label="3">非会员</el-checkbox>
                    <el-checkbox label="1">全部会员+排除</el-checkbox>
                    <el-checkbox label="2">指定会员</el-checkbox>
                    <el-checkbox label="4">生日会员</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-select
                  v-model="form.vipBirthdayType"
                  class="selectVipBD"
                  v-if="showVipBD"
                  :disabled="disabled"
                  size="mini"
                >
                  <el-option
                    :label="dict.label"
                    v-for="dict in dict.type.vip_birthday_type"
                    :key="dict.value"
                    :value="dict.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- 指定会员区域 -->
              <div class="selectVip" v-if="vipTable">
                <div class="vipBtn">
                  <el-button size="mini" @click="openSelectVip" :disabled="disabled"
                    >选择会员
                  </el-button>
                  <el-button size="mini" @click="openSelectVipRank" :disabled="disabled"
                    >选择会员等级
                  </el-button>
                </div>
                <!-- 会员表格 -->
                <comTable
                  class="marB10"
                  :columnLabel="vipTableLable"
                  :tableData="form.vipLevelItem"
                  @delTableRow="delTableRow($event, 'vip')"
                  :disabled="disabled"
                >
                  <!-- 会员表格插入类型 -->
                  <template #tableMiddle>
                    <el-table-column
                      label="类型"
                      align="center"
                      prop="lineType"
                      width="123"
                    >
                      <template slot-scope="scope">
                        <dict-tag
                          :options="dict.type.member_type"
                          :value="scope.row.lineType"
                        />
                      </template>
                    </el-table-column>
                  </template>
                </comTable>
              </div>
              <el-form-item label="适用时间" prop="useTimeRangeType">
                <el-radio-group v-model="form.useTimeRangeType" :disabled="disabled">
                  <el-radio
                    :label="dict.value"
                    v-for="dict in dict.type.use_time_range_type"
                    :key="dict.value"
                    :value="dict.value"
                    >{{ dict.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <div class="timeTable" v-if="form.useTimeRangeType === '2'">
                <div class="btn x-f marB10">
                  <el-button
                    size="mini"
                    @click="
                      handleEvent('openTimeDateDialog', {
                        curTable: 'timeDateOptions',
                      })
                    "
                    :disabled="disabled"
                    >选择时间
                  </el-button>
                  <!-- <el-button
                                        size="mini"
                                        @click="openSelectDate"
                                        :disabled="disabled"
                                    >选择时间
                                    </el-button
                                    > -->
                </div>
                <EditTable :options="timeDateOptions" />
                <!-- 时间表格 -->
                <!-- <comTable
                                    class="marB10"
                                    :columnLabel="timeTableLable"
                                    :tableData="form.specialDateItem"
                                    @delTableRow="delTableRow($event, 'time')"
                                    :disabled="disabled"
                                ></comTable> -->
              </div>
            </div>
          </template>
        </cardTitleCom>
        <!-- 活动明细：  数量折扣方案-->
        <cardTitleCom cardTitle="活动明细" v-if="form.promoType === '5'">
          <template slot="cardContent">
            <div class="activityDetail">
              <div class="top">
                <el-radio v-model="form.buyFullQtyType" label="1" :disabled="disabled"
                  >同一商品买满件数
                </el-radio>
                <el-radio v-model="form.buyFullQtyType" label="2" :disabled="disabled"
                  >适用范围内任选商品买满件数
                </el-radio>
              </div>
              <activityTable
                :form="form"
                :tableFieldName="ADTableLabel"
                @handleTableRow="DetailTableRow"
                :disabled="disabled"
              ></activityTable>
            </div>
          </template>
        </cardTitleCom>
        <!-- 活动明细：  金额折扣方案-->
        <cardTitleCom cardTitle="活动明细" v-if="form.promoType === '6'">
          <template slot="cardContent">
            <div class="activityDetail">
              <div class="top">
                <el-radio v-model="form.buyFullMoneyType" label="1" :disabled="disabled"
                  >同一商品买满金额
                </el-radio>
                <el-radio v-model="form.buyFullMoneyType" label="2" :disabled="disabled"
                  >适用范围内任选商品买满金额
                </el-radio>
              </div>
              <activityTable
                :form="form"
                :tableFieldName="MDTableLabel"
                @handleTableRow="DetailTableRow"
                :disabled="disabled"
              ></activityTable>
            </div>
          </template>
        </cardTitleCom>
        <!-- 活动明细：  金额满减方案-->
        <cardTitleCom cardTitle="活动明细" v-if="form.promoType === '7'">
          <template slot="cardContent">
            <div class="activityDetail">
              <div class="top">
                <el-radio v-model="form.buyFullMoneyType" label="1" :disabled="disabled"
                  >同一商品买满金额
                </el-radio>
                <el-radio v-model="form.buyFullMoneyType" label="2" :disabled="disabled"
                  >适用范围内任选商品买满金额
                </el-radio>
              </div>
              <activityTable
                :form="form"
                :tableFieldName="MFDTableLabel"
                @handleTableRow="DetailTableRow"
                :disabled="disabled"
              ></activityTable>
            </div>
          </template>
        </cardTitleCom>
        <!-- 活动明细：  预订优惠方案-->
        <cardTitleCom cardTitle="活动明细" v-if="form.promoType === '11'">
          <template slot="cardContent">
            <div class="activityDetail">
              <activityTable
                :form="form"
                :tableFieldName="RDTableLabel"
                @handleTableRow="DetailTableRow"
                :disabled="disabled"
              ></activityTable>
            </div>
          </template>
        </cardTitleCom>
        <!-- 活动明细：  买满赠送方案-->
        <cardTitleCom cardTitle="活动明细" v-if="form.promoType === '8'">
          <template slot="cardContent">
            <div class="activityDetail">
              <el-form-item label="买满方式" prop="buyFullLargessType">
                <el-radio-group v-model="form.buyFullLargessType" :disabled="disabled">
                  <el-radio label="0">数量满赠</el-radio>
                  <el-radio label="1" v-if="form.buyLargessType !== '0'"
                    >金额满赠</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <div class="amountFullGift" v-if="form.buyFullLargessType === '0'">
                <div class="top">
                  <el-radio v-model="form.buyFullQtyType" label="1" :disabled="disabled"
                    >同一商品买满件数
                  </el-radio>
                  <el-radio v-model="form.buyFullQtyType" label="2" :disabled="disabled"
                    >适用范围内任选商品买满件数
                  </el-radio>
                </div>
              </div>
              <div class="moneyFullGift" v-if="form.buyFullLargessType === '1'">
                <div class="top">
                  <el-radio v-model="form.buyFullMoneyType" label="1" :disabled="disabled"
                    >同一商品买满金额
                  </el-radio>
                  <el-radio v-model="form.buyFullMoneyType" label="2" :disabled="disabled"
                    >适用范围内任选商品买满金额
                  </el-radio>
                </div>
              </div>
              <el-form-item
                :label="form.buyFullLargessType === '0' ? '买满数量' : '买满金额(元)'"
                class="x-f marT10"
                :prop="`buyFullItem[${0}].buyFullValue`"
                :rules="rules[`buyFullItem.buyFullValue`]"
                label-width="105px"
              >
                <el-input
                  v-model="form.buyFullItem[0].buyFullValue"
                  :disabled="disabled"
                  size="mini"
                ></el-input>
              </el-form-item>
            </div>
          </template>
        </cardTitleCom>
        <!-- 活动明细：  充值免单方案-->
        <cardTitleCom cardTitle="活动明细" v-if="form.promoType === '9'">
          <template slot="cardContent">
            <div class="activityDetail">
              <div class="top">
                <!-- <el-radio
                                    v-model="form.buyFullMoneyType"
                                    label="1"
                                    :disabled="disabled"
                                >同一商品买满金额
                                </el-radio
                                > -->
                <el-radio v-model="form.buyFullMoneyType" label="2" :disabled="disabled"
                  >适用范围内任选商品买满金额
                </el-radio>
              </div>
              <activityTable
                class="table"
                :form="form"
                :tableFieldName="RFTableLabel"
                :specialTableColumn="true"
                @handleTableRow="DetailTableRow"
                :disabled="disabled"
              ></activityTable>
            </div>
          </template>
        </cardTitleCom>
        <!-- 活动明细:   组合优惠 -->
        <cardTitleCom cardTitle="活动明细" v-if="form.promoType === '10'">
          <template slot="cardContent">
            <div class="activityDetail">
              <el-form-item
                label="组合名称"
                class="x-f"
                :prop="`comboHead.comboHeadName`"
                :rules="rules[`comboHead.comboHeadName`]"
              >
                <el-input
                  v-model="form.comboHead.comboHeadName"
                  :disabled="disabled"
                  size="mini"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="优惠方式"
                :prop="`comboHead.discountType`"
                :rules="rules[`comboHead.discountType`]"
              >
                <el-radio-group
                  v-model="form.comboHead.discountType"
                  class="discuountWayList"
                  :disabled="disabled"
                >
                  <div class="x-bc item">
                    <el-radio label="1">特价</el-radio>
                    <el-form-item
                      label="价格(元)"
                      label-width="65px"
                      v-if="form.comboHead.discountType === '1'"
                      :prop="`comboHead.discountValue`"
                      :rules="rules[`comboHead.discountValue`]"
                    >
                      <el-input
                        v-model="form.comboHead.discountValue"
                        :disabled="disabled"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="x-bc item">
                    <el-radio label="2">优惠</el-radio>
                    <el-form-item
                      label="金额(元)"
                      label-width="65px"
                      v-if="form.comboHead.discountType === '2'"
                      :prop="`comboHead.discountValue`"
                      :rules="rules[`comboHead.discountValue`]"
                    >
                      <el-input
                        v-model="form.comboHead.discountValue"
                        :disabled="disabled"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="x-bc item">
                    <el-radio label="3">折扣</el-radio>
                    <el-form-item
                      label="折扣(%)"
                      label-width="65px"
                      v-if="form.comboHead.discountType === '3'"
                      :prop="`comboHead.discountValue`"
                      :rules="rules[`comboHead.discountValue`]"
                    >
                      <el-input
                        v-model="form.comboHead.discountValue"
                        :disabled="disabled"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </div>
                </el-radio-group>
              </el-form-item>
            </div>
          </template>
        </cardTitleCom>
        <!-- 活动明细:   额外赠送 -->
        <cardTitleCom cardTitle="活动明细" v-if="form.promoType === '12'">
          <template slot="cardContent">
            <div class="activityDetail">
              <el-form-item label="买满方式" prop="buyFullLargessType">
                <el-radio-group v-model="form.buyFullLargessType" :disabled="disabled">
                  <el-radio label="0">数量满赠</el-radio>
                  <el-radio label="1">金额满赠</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                :label="form.buyFullLargessType === '0' ? '买满数量' : '买满金额(元)'"
                class="x-f marT10"
                :prop="`buyFullItem[${0}].buyFullValue`"
                :rules="rules[`buyFullItem.buyFullValue`]"
                label-width="105px"
              >
                <el-input
                  v-model="form.buyFullItem[0].buyFullValue"
                  :disabled="disabled"
                  size="mini"
                  @input="
                    limitInputlength(
                      form.buyFullItem[0].buyFullValue,
                      form.buyFullItem[0],
                      'buyFullValue',
                      true,
                      4
                    )
                  "
                ></el-input>
              </el-form-item>
            </div>
          </template>
        </cardTitleCom>
        <!--参与活动的商品： 特价促销方案 折扣优惠方案 数量折扣方案 金额折扣方案 金额满减方案 买满赠送方案 充值免单方案 共用 -->
        <cardTitleCom
          cardTitle="参与活动的商品"
          v-if="
            form.promoType === '0' ||
            form.promoType === '4' ||
            form.promoType === '5' ||
            form.promoType === '6' ||
            form.promoType === '7' ||
            form.promoType === '8' ||
            form.promoType === '9' ||
            form.promoType === '11' ||
            form.promoType === '12'
          "
        >
          <template slot="cardContent">
            <div class="goods1">
              <div class="top">
                <el-form-item label="适用商品" prop="goodsRangeType">
                  <el-radio-group v-model="form.goodsRangeType" :disabled="disabled">
                    <el-radio
                      :label="dict.value"
                      v-for="dict in dict.type.vip_goods_range_type"
                      :key="dict.value"
                      :value="dict.value"
                      >{{ dict.label }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <!-- 选择全部商品/全部商品+排除 特价促销方案显示可输入特价字段 折扣优惠放方案显示可输入折扣字段 -->
              <div
                class="allGoods"
                v-if="form.goodsRangeType === '0' || form.goodsRangeType === '1'"
              >
                <el-form-item
                  label-width="110px"
                  label="全部特价(元)"
                  v-if="form.promoType === '0'"
                  class="x-f marT15"
                  prop="allSpecialPrice"
                >
                  <el-input
                    v-model="form.allSpecialPrice"
                    :disabled="disabled"
                    size="mini"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label-width="110px"
                  label="全部折扣(%)"
                  v-if="form.promoType === '4'"
                  class="x-f marT15"
                  prop="allSpecialDiscount"
                >
                  <el-input
                    v-model="form.allSpecialDiscount"
                    :disabled="disabled"
                    size="mini"
                  ></el-input>
                </el-form-item>
              </div>
              <!-- 商品表格区域 -->
              <div
                class="table"
                v-if="form.goodsRangeType === '1' || form.goodsRangeType === '2'"
              >
                <div class="btn x-f">
                  <el-button size="mini" @click="openSelectGoods" :disabled="disabled"
                    >选择商品
                  </el-button>
                  <el-button size="mini" @click="openSelectCategory" :disabled="disabled"
                    >选择类别
                  </el-button>
                  <el-button size="mini" @click="openSelectBrand" :disabled="disabled"
                    >选择品牌
                  </el-button>
                </div>
                <!-- 表格组件 -->
                <Ttable
                  :tableData="form.goodsItem"
                  @delTableRow="delTableRow($event, 'goods')"
                  :disabled="disabled"
                >
                  <!-- 特价方案 显示特价-->
                  <template
                    #discountPrice
                    v-if="
                      (form.promoType === '0' || form.promoType === '4') &&
                      form.goodsRangeType == 2
                    "
                  >
                    <el-table-column
                      label="特价(元)"
                      align="center"
                      width="180"
                      v-if="form.promoType === '0'"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        特价(元)
                      </template>
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.promoPrice"
                          :disabled="disabled"
                          size="mini"
                        ></el-input>
                      </template>
                    </el-table-column>
                    <!-- 折扣优惠 显示折扣 -->
                    <el-table-column
                      label="折扣(%)"
                      align="center"
                      width="180"
                      v-if="form.promoType === '4'"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        折扣(%)
                      </template>
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.discount"
                          size="mini"
                          :disabled="disabled"
                        ></el-input>
                      </template>
                    </el-table-column>
                  </template>
                </Ttable>
              </div>
            </div>
          </template>
        </cardTitleCom>
        <!--参与活动的商品： 数量特价方案 -->
        <cardTitleCom cardTitle="参与活动的商品" v-if="form.promoType === '1'">
          <template slot="cardContent">
            <div class="goods2">
              <div class="left">
                <div class="topLeft">
                  <el-button
                    icon="el-icon-plus"
                    @click="handleAddGoods"
                    :disabled="disabled"
                    >添加商品优惠
                  </el-button>
                </div>
                <div class="main">
                  <!-- 隐藏横向滚动条 -->
                  <el-scrollbar
                    style="height: 100%; width: 100%"
                    wrap-style="overflow-x:hidden;"
                  >
                    <!-- 表格区域 -->
                    <div class="list">
                      <!-- 每一项 -->
                      <div
                        v-for="(item, index) in form.goodsItem"
                        :key="item.lineId"
                        class="item"
                        :class="{ focusItem: clickGoodsIndex === index }"
                        @click="focusGoodsItem(index)"
                      >
                        <div class="top">
                          <span>{{ item.lineNo || "" }}</span>
                          <i
                            class="el-icon-delete more"
                            @click.stop="handleDelGoods(index)"
                            v-if="!disabled"
                          ></i>
                        </div>
                        <div class="name">
                          {{ item.lineName || "商品名称" }}
                        </div>
                        <!-- 折扣列表区域 -->
                        <div
                          class="discountList"
                          v-if="item.goodsBuyFullItem && item.goodsName"
                        >
                          <el-scrollbar
                            style="height: 100%; width: 100%"
                            wrap-style="overflow-x:hidden;"
                          >
                            <div
                              v-for="(v, i) in item.goodsBuyFullItem"
                              :key="i"
                              class="discountItem"
                            >
                              <span>{{
                                v.buyFullQty ? `满${v.buyFullQty}个,` : ""
                              }}</span>
                              <span>{{
                                v.specialPrice ? `${v.specialPrice}元` : ""
                              }}</span>
                            </div>
                          </el-scrollbar>
                        </div>
                      </div>
                    </div>
                  </el-scrollbar>
                </div>
              </div>
              <div class="right">
                <div class="topRight">
                  <i style="color: #ff4949; margin-left: 20px">*</i>
                  <el-form-item label="商品编码">
                    <el-input
                      v-model="form.goodsItem[`${clickGoodsIndex}`].lineNo"
                      :disabled="disabled"
                      size="mini"
                      @keyup.enter.native="goodsKeyupEnter($event)"
                    >
                      <i
                        slot="suffix"
                        class="el-icon-more"
                        @click="openSelectGoods(true)"
                      ></i>
                    </el-input>
                  </el-form-item>
                  <el-form-item style="margin-left: 20px" label="商品名称">
                    <el-input
                      disabled
                      size="mini"
                      v-model="form.goodsItem[`${clickGoodsIndex}`].lineName"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="单位" style="margin-left: 20px">
                    <el-select
                      :disabled="disabled"
                      size="mini"
                      v-model="form.goodsItem[`${clickGoodsIndex}`].unitId"
                      placeholder="请选择单位"
                      @change="
                        changeUnitPrice(
                          form.goodsItem[`${clickGoodsIndex}`],
                          form.goodsItem[`${clickGoodsIndex}`].units,
                          form.goodsItem[`${clickGoodsIndex}`].unitId
                        )
                      "
                    >
                      <el-option
                        v-for="item in form.goodsItem[`${clickGoodsIndex}`].units"
                        :key="item.unitId"
                        :label="item.unitName"
                        :value="item.unitId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="零售价" style="margin-left: 20px">
                    <el-input
                      v-model="form.goodsItem[`${clickGoodsIndex}`].salePrice"
                      size="mini"
                      disabled
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="main">
                  <el-scrollbar
                    style="height: 100%; width: 100%"
                    wrap-style="overflow-x:hidden;"
                  >
                    <!-- 折扣优惠列表 -->
                    <div class="discountDetailList">
                      <div class="leftLabel">优惠规则:</div>
                      <div class="rightCentent">
                        <div
                          class="item"
                          v-for="(item, index) in form.goodsItem[`${clickGoodsIndex}`]
                            .goodsBuyFullItem"
                          :key="index"
                        >
                          <el-form-item
                            label="满"
                            :prop="`goodsItem[${clickGoodsIndex}].goodsBuyFullItembuyFullQty`"
                            :rules="rules[`details.unitQty`]"
                          >
                            <el-input
                              v-model="item.buyFullQty"
                              size="mini"
                              :disabled="disabled"
                            ></el-input>
                            <div>个,</div>
                          </el-form-item>
                          <el-form-item label="特价">
                            <el-input
                              v-model="item.specialPrice"
                              :disabled="disabled"
                              size="mini"
                            ></el-input>
                            <div>元</div>
                          </el-form-item>
                          <div
                            class="del"
                            @click="handleDelRule(index)"
                            :disabled="disabled"
                          >
                            删除
                          </div>
                        </div>
                        <div class="ruleAdd">
                          <el-button
                            size="mini"
                            @click="handleAddRule"
                            :disabled="disabled"
                            >添加规则
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </el-scrollbar>
                </div>
              </div>
            </div>
          </template>
        </cardTitleCom>
        <!--参与活动的商品： 固定组合方案 -->
        <cardTitleCom cardTitle="参与活动的商品" v-if="form.promoType === '2'">
          <template slot="cardContent">
            <div class="goods3">
              <div class="list">
                <div class="item" v-for="(item, index) in form.comboItem" :key="index">
                  <div class="top x-bc">
                    <div class="left x-f">
                      <el-form-item
                        label="组合名称"
                        class="marR50"
                        label-width="80px"
                        :prop="`comboItem[${index}].comboName`"
                        :rules="rules[`comboItem.comboName`]"
                      >
                        <el-input
                          placeholder="请输入"
                          v-model="item.comboName"
                          size="mini"
                          :disabled="disabled"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="组合价格(元)"
                        label-width="105px"
                        :prop="`comboItem[${index}].comboPrice`"
                        :rules="rules[`comboItem.comboPrice`]"
                      >
                        <el-input
                          placeholder="请输入"
                          v-model="item.comboPrice"
                          size="mini"
                          :disabled="disabled"
                        ></el-input>
                        <div class="total">零售价合计</div>
                      </el-form-item>
                    </div>
                    <div class="rightBtn">
                      <el-button
                        type="primary"
                        size="mini"
                        icon="el-icon-plus"
                        v-if="index === 0"
                        @click="handleRow('push', index)"
                        :disabled="disabled"
                        >添加固定组合
                      </el-button>
                      <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="mini"
                        @click="handleRow('del', index)"
                        :disabled="disabled"
                        >删除该组合
                      </el-button>
                    </div>
                  </div>
                  <!-- 商品表格区域 -->
                  <div class="table">
                    <el-table
                      class="table"
                      ref="dragTable"
                      :data="item.comboGoodsItem"
                      border
                      max-height="300"
                      key="lineId"
                    >
                      <el-table-column
                        label="序号"
                        type="index"
                        width="50"
                        class-name="allowDrag"
                        align="center"
                      />
                      <el-table-column
                        align="center"
                        label="操作"
                        width="120"
                        :v-if="!disabled"
                      >
                        <template v-slot="scope">
                          <i
                            @click="tableRow(item.comboGoodsItem, 'push', scope.$index)"
                            class="el-icon-circle-plus operatePush"
                          ></i>
                          <i
                            @click="tableRow(item.comboGoodsItem, 'del', scope.$index)"
                            class="el-icon-remove operateDel"
                          ></i>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="商品编码"
                        width="260"
                        align="center"
                        prop="lineNo"
                      >
                        <template slot="header">
                          <i style="color: #ff4949">*</i>
                          商品编码
                        </template>
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="`comboItem[${index}].comboGoodsItem[${scope.$index}].lineNo`"
                            :rules="rules[`comboItem.comboGoodsItem.lineNo`]"
                          >
                            <el-input
                              v-model="scope.row.lineNo"
                              :disabled="disabled"
                              size="mini"
                              @keyup.enter.native="goodsKeyupEnter($event, index)"
                            >
                              <i
                                slot="suffix"
                                class="el-icon-more"
                                @click="openSelectGoods(index)"
                                :disabled="disabled"
                              ></i>
                            </el-input>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="商品名称"
                        align="center"
                        prop="lineName"
                        show-overflow-tooltip
                      >
                      </el-table-column>
                      <el-table-column label="单位" align="center" width="260">
                        <template slot-scope="scope">
                          <el-select
                            :disabled="disabled"
                            size="mini"
                            v-model="scope.row.unitId"
                            @change="
                              changeUnitPrice(
                                scope.row,
                                scope.row.units,
                                scope.row.unitId
                              )
                            "
                          >
                            <el-option
                              v-for="item in scope.row.units"
                              :key="item.unitId"
                              :label="item.unitName"
                              :value="item.unitId"
                            />
                          </el-select>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="售价(元)"
                        align="center"
                        width="200"
                        prop="salePrice"
                      >
                      </el-table-column>
                      <el-table-column
                        label="数量"
                        align="center"
                        width="200"
                        prop="limitQty"
                      >
                        <template slot="header">
                          <i style="color: #ff4949">*</i>
                          数量
                        </template>
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="`comboItem[${index}].comboGoodsItem[${scope.$index}].limitQty`"
                            :rules="rules[`comboItem.comboGoodsItem.limitQty`]"
                          >
                            <el-input
                              v-model="scope.row.limitQty"
                              :disabled="disabled"
                              size="mini"
                            >
                            </el-input>
                          </el-form-item>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </cardTitleCom>
        <!--参与活动的商品： 动态组合方案 -->
        <cardTitleCom cardTitle="参与活动的商品" v-if="form.promoType === '3'">
          <template slot="cardContent">
            <div class="goods3">
              <div class="list">
                <div class="item" v-for="(item, index) in form.comboItem" :key="index">
                  <div class="top x-bc">
                    <div class="left x-f">
                      <el-form-item
                        label="组合名称"
                        class="marR50"
                        label-width="80px"
                        :prop="`comboItem[${index}].comboName`"
                        :rules="rules[`comboItem.comboName`]"
                      >
                        <el-input
                          placeholder="请输入"
                          v-model="item.comboName"
                          :disabled="disabled"
                          size="mini"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="组合价格(元)"
                        class="marR50"
                        label-width="105px"
                        :prop="`comboItem[${index}].comboPrice`"
                        :rules="rules[`comboItem.comboPrice`]"
                      >
                        <el-input
                          placeholder="请输入"
                          v-model="item.comboPrice"
                          size="mini"
                          :disabled="disabled"
                        ></el-input>
                        <div class="total">零售价合计</div>
                      </el-form-item>
                      <el-form-item
                        label="任意"
                        label-width="70px"
                        :prop="`comboItem[${index}].comboSelectQty`"
                        :rules="rules[`comboItem.comboSelectQty`]"
                      >
                        <el-input
                          placeholder="请输入"
                          v-model="item.comboSelectQty"
                          size="mini"
                          :disabled="disabled"
                        ></el-input>
                        <div class="total">件</div>
                      </el-form-item>
                      <!-- <div class="x-f">
                                                <el-radio v-model="item.arbitrarily" label="1"
                                                >任意
                                                </el-radio
                                                >
                                                <el-form-item
                                                    class="num"
                                                    :prop="`comboItem[${index}].comboSelectQty`"
                                                    :rules="rules[`comboItem.comboSelectQty`]"
                                                >
                                                    <el-input
                                                        placeholder="请输入"
                                                        v-model="item.comboSelectQty"
                                                        :disabled="disabled"
                                                        size="mini"
                                                    ></el-input>
                                                    <div>件</div>
                                                </el-form-item>
                                            </div> -->
                    </div>
                    <div class="rightBtn">
                      <el-button
                        type="primary"
                        size="mini"
                        icon="el-icon-plus"
                        v-if="index === 0"
                        @click="handleRow('push', index)"
                        :disabled="disabled"
                        >添加动态组合
                      </el-button>
                      <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="mini"
                        @click="handleRow('del', index)"
                        :disabled="disabled"
                        >删除该组合
                      </el-button>
                    </div>
                  </div>
                  <!-- 商品表格区域 -->
                  <div class="table">
                    <div class="btn x-f marB10">
                      <el-button
                        size="mini"
                        @click="openSelectGoods(index)"
                        :disabled="disabled"
                        >选择商品
                      </el-button>
                      <el-button
                        size="mini"
                        @click="openSelectCategory(index)"
                        :disabled="disabled"
                        >选择类别
                      </el-button>
                      <el-button
                        size="mini"
                        @click="openSelectBrand(index)"
                        :disabled="disabled"
                        >选择品牌
                      </el-button>
                    </div>
                    <!-- 表格组件 -->
                    <Ttable
                      :tableData="item.comboGoodsItem"
                      @delTableRow="delTableRow($event, 'comboGoodsItem', index)"
                      :disabled="disabled"
                    >
                      <!-- 动态组合方案 最多可选数量-->
                      <template #discountPrice>
                        <el-table-column label="最多可选数量" align="center" width="180">
                          <template slot="header">
                            <i style="color: #ff4949">*</i>
                            最多可选数量
                          </template>
                          <template slot-scope="scope">
                            <el-form-item
                              :prop="`comboItem[${index}].comboGoodsItem[${scope.$index}].limitQty`"
                              :rules="rules[`comboItem.comboGoodsItem.limitQty`]"
                            >
                              <el-input
                                v-model="scope.row.limitQty"
                                :disabled="disabled"
                                @blur="
                                  blurChange(scope.row.limitQty, scope.$index, index)
                                "
                                oninput="value=value.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
                                size="mini"
                              ></el-input>
                            </el-form-item>
                          </template>
                        </el-table-column>
                      </template>
                    </Ttable>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </cardTitleCom>
        <!--参与活动的商品： 组合优惠方案 -->
        <cardTitleCom cardTitle="参与活动的商品" v-if="form.promoType === '10'">
          <template slot="cardContent">
            <div class="goods3">
              <div class="list">
                <div class="item" v-for="(item, index) in form.comboItem" :key="index">
                  <div class="top x-bc">
                    <div class="left x-f">
                      <el-form-item
                        label="分类组合名称"
                        class="marR50"
                        label-width="110px"
                        :prop="`comboItem[${index}].comboName`"
                        :rules="rules[`comboItem.comboName`]"
                      >
                        <el-input
                          placeholder="请输入"
                          v-model="item.comboName"
                          size="mini"
                          :disabled="disabled"
                        ></el-input>
                      </el-form-item>
                      <el-radio-group v-model="item.comboSelectType" :disabled="disabled">
                        <el-radio label="0">必选</el-radio>
                        <el-radio label="1">可选</el-radio>
                      </el-radio-group>
                      <el-form-item
                        label="数量"
                        label-width="60px"
                        class="num marL15"
                        :prop="`comboItem[${index}].comboSelectQty`"
                        :rules="rules[`comboItem.comboSelectQty`]"
                      >
                        <el-input
                          v-model="item.comboSelectQty"
                          :disabled="disabled"
                          size="mini"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="rightBtn">
                      <el-button
                        type="primary"
                        size="mini"
                        icon="el-icon-plus"
                        v-if="index === 0"
                        @click="handleRow('push', index)"
                        :disabled="disabled"
                        >添加组合
                      </el-button>
                      <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="mini"
                        @click="handleRow('del', index)"
                        :disabled="disabled"
                        >删除该组合
                      </el-button>
                    </div>
                  </div>
                  <!-- 商品表格区域 -->
                  <div class="table">
                    <el-table
                      class="table"
                      ref="dragTable"
                      :data="item.comboGoodsItem"
                      border
                      max-height="300"
                    >
                      <el-table-column
                        label="序号"
                        type="index"
                        width="80"
                        align="center"
                      />
                      <el-table-column
                        align="center"
                        label="操作"
                        width="120"
                        v-if="!disabled"
                      >
                        <template v-slot="scope">
                          <i
                            @click="tableRow(item.comboGoodsItem, 'push', scope.$index)"
                            class="el-icon-circle-plus operatePush"
                          ></i>
                          <i
                            @click="tableRow(item.comboGoodsItem, 'del', scope.$index)"
                            class="el-icon-remove operateDel"
                          ></i>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="编码"
                        width="260"
                        align="center"
                        prop="lineNo"
                      >
                        <template slot="header">
                          <i style="color: #ff4949">*</i>
                          商品编码
                        </template>
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="`comboItem[${index}].comboGoodsItem[${scope.$index}].lineNo`"
                            :rules="rules[`comboItem.comboGoodsItem.lineNo`]"
                            @keyup.enter.native="goodsKeyupEnter($event, index)"
                          >
                            <el-input
                              v-model="scope.row.lineNo"
                              :disabled="disabled"
                              size="mini"
                            >
                              <i
                                slot="suffix"
                                class="el-icon-more more"
                                @click="openSelectGoods(index)"
                              ></i>
                            </el-input>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="名称"
                        align="center"
                        prop="lineName"
                        show-overflow-tooltip
                      >
                      </el-table-column>
                      <el-table-column label="单位" align="center" width="260">
                        <template slot-scope="scope">
                          <el-select
                            :disabled="disabled"
                            size="mini"
                            v-model="scope.row.unitId"
                            @change="
                              changeUnitPrice(
                                scope.row,
                                scope.row.units,
                                scope.row.unitId
                              )
                            "
                          >
                            <el-option
                              v-for="item in scope.row.units"
                              :key="item.unitId"
                              :label="item.unitName"
                              :value="item.unitId"
                            />
                          </el-select>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="售价(元)"
                        align="center"
                        width="200"
                        prop="salePrice"
                      >
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </cardTitleCom>
        <!--赠送的商品/礼券: 买满赠送方案 -->
        <cardTitleCom cardTitle="赠送的商品/礼券" v-if="form.promoType === '8'">
          <template slot="cardContent">
            <div class="goods4">
              <el-form-item label="买赠方式" prop="buyLargessType">
                <el-radio-group v-model="form.buyLargessType" :disabled="disabled">
                  <el-radio label="0">任选N件</el-radio>
                  <el-radio label="1">固定赠品</el-radio>
                  <el-radio label="2">赠送优惠券</el-radio>
                  <el-radio label="3">买一赠一</el-radio>
                </el-radio-group>
                <el-tooltip placement="top" style="marginleft: 10px">
                  <div slot="content">
                    Tips:
                    买一赠一规则，活动明细设置将无效，系统将自动使用买一送一的规则处理
                  </div>
                  <i class="el-icon-question" />
                </el-tooltip>
              </el-form-item>
              <!-- 任选N件 -->
              <div class="optional" v-if="form.buyLargessType === '0'">
                <el-form-item
                  label-width="110px"
                  label="赠送数量(件)"
                  class="x-f"
                  prop="buyLargessQty"
                >
                  <el-input
                    size="mini"
                    v-model="form.buyLargessQty"
                    :disabled="disabled"
                  ></el-input>
                </el-form-item>
                <!-- 商品表格区域 -->
                <div class="table marT10">
                  <div class="btn x-f">
                    <el-button
                      size="mini"
                      @click="openSelectGoods('', 'giveGoodsTable')"
                      :disabled="disabled"
                      >选择商品
                    </el-button>
                    <el-button
                      size="mini"
                      @click="openSelectCategory('', 'giveGoodsTable')"
                      :disabled="disabled"
                      >选择类别
                    </el-button>
                    <el-button
                      size="mini"
                      @click="openSelectBrand('', 'giveGoodsTable')"
                      :disabled="disabled"
                      >选择品牌
                    </el-button>
                  </div>
                  <!-- 表格组件 -->
                  <Ttable
                    :tableData="form.optionalGoodsItem"
                    @delTableRow="delTableRow($event, 'optionalGoodsItem')"
                    :disabled="disabled"
                  >
                  </Ttable>
                </div>
              </div>
              <!-- 固定赠品 -->
              <div class="flxedGift" v-if="form.buyLargessType === '1'">
                <!-- 商品表格组件 -->
                <div class="flxedGiftTable marT10">
                  <el-table
                    class="goods-table"
                    ref="goods-table"
                    :data="form.fixedGoodsItem"
                    border
                    max-height="300"
                    row-key="lineId"
                  >
                    <el-table-column
                      label="序号"
                      type="index"
                      width="50"
                      align="center"
                    />
                    <el-table-column
                      align="center"
                      label="操作"
                      width="120"
                      v-if="!disabled"
                    >
                      <template v-slot="scope">
                        <i
                          @click="tableRow(form.fixedGoodsItem, 'push', scope.$index)"
                          class="el-icon-circle-plus operatePush"
                        ></i>
                        <i
                          @click="tableRow(form.fixedGoodsItem, 'del', scope.$index)"
                          class="el-icon-remove operateDel"
                        ></i>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="商品编码"
                      width="220"
                      align="center"
                      prop="lineNo"
                      key="lineNo"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        商品编码
                      </template>
                      <template slot-scope="scope">
                        <el-form-item
                          label-width="0px"
                          :prop="`fixedGoodsItem[${scope.$index}].lineNo`"
                          :rules="rules[`fixedGoodsItem.lineNo`]"
                        >
                          <el-input
                            size="mini"
                            v-model="scope.row.lineNo"
                            :disabled="disabled"
                            @keyup.enter.native="goodsKeyupEnter($event)"
                          >
                            <i
                              slot="suffix"
                              class="el-icon-more more"
                              @click="openSelectGoods('', 'giveGoodsTable')"
                            ></i>
                          </el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="商品名称"
                      align="center"
                      prop="lineName"
                      key="lineName"
                    >
                    </el-table-column>
                    <el-table-column
                      label="规格"
                      align="center"
                      prop="goodsSpec"
                      key="goodsSpec"
                    >
                    </el-table-column>
                    <el-table-column
                      label="单位"
                      align="center"
                      key="unitId"
                      prop="unitId"
                    >
                      <template slot-scope="scope">
                        <el-form-item>
                          <el-select
                            :disabled="disabled"
                            size="mini"
                            v-model="scope.row.unitId"
                            @change="
                              changeUnitPrice(
                                scope.row,
                                scope.row.units,
                                scope.row.unitId
                              )
                            "
                          >
                            <el-option
                              v-for="item in scope.row.units"
                              :key="item.unitId"
                              :label="item.unitName"
                              :value="item.unitId"
                            />
                          </el-select>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="数量"
                      align="center"
                      key="limitQty"
                      prop="limitQty"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        数量
                      </template>
                      <template slot-scope="scope">
                        <el-form-item
                          :prop="`fixedGoodsItem[${scope.$index}].limitQty`"
                          :rules="rules[`fixedGoodsItem.limitQty`]"
                        >
                          <el-input
                            v-model="scope.row.limitQty"
                            :disabled="disabled"
                            size="mini"
                          >
                          </el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="加购金额"
                      align="center"
                      key="promoPrice"
                      prop="promoPrice"
                    >
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.promoPrice"
                          :disabled="disabled"
                          size="mini"
                        >
                        </el-input>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <!-- 赠送礼券 -->
              <div class="sendCoupon" v-if="form.buyLargessType === '2'">
                <!-- 优惠券表格区域 -->
                <div class="CouponTable marT10">
                  <el-table
                    class="couponTable"
                    ref="couponTable"
                    :data="form.couponLargessItem"
                    border
                    max-height="300"
                    row-key="couponCaseId"
                  >
                    <el-table-column
                      label="序号"
                      type="index"
                      width="50"
                      align="center"
                    />
                    <el-table-column
                      align="center"
                      label="操作"
                      width="120"
                      :v-if="!disabled"
                    >
                      <template v-slot="scope">
                        <i
                          @click="tableRow(form.couponLargessItem, 'push', scope.$index)"
                          class="el-icon-circle-plus operatePush"
                        ></i>
                        <i
                          @click="tableRow(form.couponLargessItem, 'del', scope.$index)"
                          class="el-icon-remove operateDel"
                        ></i>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="优惠券方案编号"
                      width="220"
                      align="center"
                      prop="couponCaseNo"
                      key="couponCaseNo"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        优惠券方案编号
                      </template>
                      <template slot-scope="scope">
                        <el-form-item
                          label-width="0px"
                          :prop="`couponLargessItem[${scope.$index}].couponCaseNo`"
                          :rules="rules[`couponLargessItem.couponCaseNo`]"
                          @keyup.enter.native="couponKeyupEnter($event, scope.$index)"
                        >
                          <el-input v-model="scope.row.couponCaseNo" :disabled="disabled">
                            <i
                              slot="suffix"
                              class="el-icon-more more"
                              @click="handleEvent('openCouponDialog')"
                              :disabled="disabled"
                              size="mini"
                            ></i>
                            <!-- @click="OpenCoupon = true" -->
                          </el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="优惠券名称"
                      align="center"
                      prop="couponCaseName"
                      show-overflow-tooltip
                      key="couponCaseName"
                    >
                    </el-table-column>
                    <el-table-column
                      label="优惠券类型"
                      align="center"
                      prop="couponCaseTypeName"
                      key="couponCaseTypeName"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.couponCaseTypeName }}
                        <!-- <dict-tag
                                                    :options="dict.type.coupon_case_type"
                                                    :value="scope.row.couponCaseType"
                                                /> -->
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="优惠券形式"
                      align="center"
                      prop="couponModeName"
                      key="couponModeName"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.couponModeName }}
                        <!-- <dict-tag
                                                    :options="dict.type.coupon_mode"
                                                    :value="scope.row.couponMode"
                                                /> -->
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="优惠券内容"
                      align="center"
                      prop="couponDesc"
                      :show-overflow-tooltip="true"
                      key="couponDesc"
                    >
                    </el-table-column>
                    <el-table-column
                      label="有效期时间"
                      align="center"
                      prop="expirationDate"
                      key="expirationDate"
                      :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column
                      label="生效方式"
                      align="center"
                      prop="couponEffectModeName"
                      key="couponEffectModeName"
                      :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column
                      label="数量"
                      align="center"
                      prop="limitQty"
                      key="limitQty"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        数量
                      </template>
                      <template slot-scope="scope">
                        <el-form-item
                          :prop="`couponLargessItem[${scope.$index}].limitQty`"
                          :rules="rules[`couponLargessItem.limitQty`]"
                        >
                          <el-input
                            v-model="scope.row.limitQty"
                            :disabled="disabled"
                            size="mini"
                          >
                          </el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="不允许会员支付赠送"
                      align="center"
                      prop="isNotVipPayLargess"
                      key="isNotVipPayLargess"
                    >
                      <template slot-scope="scope">
                        <el-form-item
                          :prop="`couponLargessItem[${scope.$index}].isNotVipPayLargess`"
                        >
                          <el-switch
                            v-model="scope.row.isNotVipPayLargess"
                            :disabled="disabled"
                            size="mini"
                          >
                          </el-switch>
                        </el-form-item>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <!-- 买一赠一 -->
              <div class="salesWith" v-if="form.buyLargessType === '3'">
                <el-form-item
                  label="赠品规则"
                  :prop="`ruleLargessItem[${0}].largessRule`"
                  :rules="rules[`ruleLargessItem.largessRule`]"
                >
                  <el-radio-group
                    v-model="form.ruleLargessItem[0].largessRule"
                    :disabled="disabled"
                  >
                    <div class="x-bc item">
                      <el-radio label="0">买一赠一(赠送购买的商品)</el-radio>
                      <el-form-item
                        :prop="`ruleLargessItem[${0}].addMoney`"
                        :rules="rules[`ruleLargessItem.addMoney`]"
                        label="加购金额(元)"
                        label-width="90px"
                        v-if="form.ruleLargessItem[0].largessRule === '0'"
                      >
                        <el-input
                          v-model="form.ruleLargessItem[0].addMoney"
                          :disabled="disabled"
                          size="mini"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="x-bc item">
                      <el-radio label="1"
                        >买一赠一(赠送价格低于购买商品的任意商品)
                      </el-radio>
                      <el-form-item
                        label="加购金额(元)"
                        label-width="90px"
                        v-if="form.ruleLargessItem[0].largessRule === '1'"
                        :prop="`ruleLargessItem[${0}].addMoney`"
                        :rules="rules[`ruleLargessItem.addMoney`]"
                      >
                        <el-input
                          v-model="form.ruleLargessItem[0].addMoney"
                          :disabled="disabled"
                          size="mini"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="x-bc item">
                      <el-radio label="2">同品第二件折扣</el-radio>
                      <el-form-item
                        label="折扣比例(%)"
                        label-width="90px"
                        v-if="form.ruleLargessItem[0].largessRule === '2'"
                        :prop="`ruleLargessItem[${0}].discountRate`"
                        :rules="rules[`ruleLargessItem.discountRate`]"
                      >
                        <el-input
                          v-model="form.ruleLargessItem[0].discountRate"
                          :disabled="disabled"
                          size="mini"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="x-bc item">
                      <el-radio label="3">同类第二件折扣</el-radio>
                      <el-form-item
                        label="折扣比例(%)"
                        label-width="90px"
                        v-if="form.ruleLargessItem[0].largessRule === '3'"
                        :prop="`ruleLargessItem[${0}].discountRate`"
                        :rules="rules[`ruleLargessItem.discountRate`]"
                      >
                        <el-input
                          v-model="form.ruleLargessItem[0].discountRate"
                          :disabled="disabled"
                          size="mini"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="x-bc item">
                      <el-radio label="4">同品第二件加购</el-radio>
                      <el-form-item
                        label="加购金额(元)"
                        label-width="90px"
                        v-if="form.ruleLargessItem[0].largessRule === '4'"
                        :prop="`ruleLargessItem[${0}].addMoney`"
                        :rules="rules[`ruleLargessItem.addMoney`]"
                      >
                        <el-input
                          v-model="form.ruleLargessItem[0].addMoney"
                          :disabled="disabled"
                          size="mini"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="x-bc item">
                      <el-radio label="5">同类第二件加购</el-radio>
                      <el-form-item
                        label="加购金额(元)"
                        label-width="90px"
                        v-if="form.ruleLargessItem[0].largessRule === '5'"
                        :prop="`ruleLargessItem[${0}].addMoney`"
                        :rules="rules[`ruleLargessItem.addMoney`]"
                      >
                        <el-input
                          v-model="form.ruleLargessItem[0].addMoney"
                          :disabled="disabled"
                          size="mini"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </el-radio-group>
                </el-form-item>
              </div>
            </div>
          </template>
        </cardTitleCom>
        <!--赠送的商品: 额外赠送方案 -->
        <cardTitleCom cardTitle="赠送的商品" v-if="form.promoType === '12'">
          <template slot="cardContent">
            <div class="goods4">
              <div class="flxedGift">
                <!-- 商品表格组件 -->
                <div class="flxedGiftTable marT10">
                  <el-table
                    class="goods-table"
                    ref="goods-table"
                    :data="form.goodsLargessItem"
                    border
                    max-height="300"
                    row-key="lineId"
                  >
                    <el-table-column
                      label="序号"
                      type="index"
                      width="50"
                      align="center"
                    />
                    <el-table-column
                      align="center"
                      label="操作"
                      width="120"
                      v-if="!disabled"
                    >
                      <template v-slot="scope">
                        <i
                          @click="tableRow(form.goodsLargessItem, 'push', scope.$index)"
                          class="el-icon-circle-plus operatePush"
                        ></i>
                        <i
                          @click="tableRow(form.goodsLargessItem, 'del', scope.$index)"
                          class="el-icon-remove operateDel"
                        ></i>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="商品编码"
                      width="220"
                      align="center"
                      prop="lineNo"
                      key="lineNo"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        商品编码
                      </template>
                      <template slot-scope="scope">
                        <el-form-item
                          label-width="0px"
                          :prop="`goodsLargessItem[${scope.$index}].lineNo`"
                          :rules="rules[`goodsLargessItem.lineNo`]"
                        >
                          <el-input
                            size="mini"
                            v-model="scope.row.lineNo"
                            :disabled="disabled"
                            @keyup.enter.native="goodsKeyupEnter($event)"
                          >
                            <i
                              slot="suffix"
                              class="el-icon-more more"
                              @click="openSelectGoods(scope.$index, 'goodsLargessItem')"
                            ></i>
                          </el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="商品名称"
                      align="center"
                      prop="lineName"
                      key="lineName"
                    >
                    </el-table-column>
                    <el-table-column
                      label="规格"
                      align="center"
                      prop="goodsSpec"
                      key="goodsSpec"
                    >
                    </el-table-column>
                    <el-table-column
                      label="单位"
                      align="center"
                      key="unitId"
                      prop="unitId"
                    >
                      <template slot-scope="scope">
                        <el-form-item>
                          <el-select
                            :disabled="disabled"
                            size="mini"
                            v-model="scope.row.unitId"
                            @change="
                              changeUnitPrice(
                                scope.row,
                                scope.row.units,
                                scope.row.unitId
                              )
                            "
                          >
                            <el-option
                              v-for="item in scope.row.units"
                              :key="item.unitId"
                              :label="item.unitName"
                              :value="item.unitId"
                            />
                          </el-select>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column label="数量" align="center">
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        数量
                      </template>
                      <template slot-scope="scope">
                        <!--    :prop="`goodsLargessItem[${scope.$index}].limitQty`"
                          :rules="rules[`goodsLargessItem.limitQty`]" -->
                        <el-form-item>
                          <el-input
                            v-model="scope.row.limitQty"
                            :disabled="disabled"
                            size="mini"
                            @input="
                              limitInputlength(
                                scope.row.limitQty,
                                scope.row,
                                'limitQty',
                                true,
                                4
                              )
                            "
                          ></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="备注">
          <template slot="cardContent">
            <div class="remark">
              <el-form-item label="方案备注">
                <el-input
                  class="billRemarkArea"
                  type="textarea"
                  placeholder="请输入方案备注备注"
                  v-model="form.billRemark"
                  maxlength="200"
                  show-word-limit
                  :rows="3"
                  :disabled="disabled"
                  size="mini"
                >
                </el-input>
              </el-form-item>
            </div>
          </template>
        </cardTitleCom>
      </el-form>
      <!-- 选择门店对话框 -->
      <selectShop
        :OpenShop.sync="OpenShop"
        @getShopData="getPitchData($event, 'shop')"
      ></selectShop>
      <!-- 选择会员级别对话框 -->
      <selectVipRank
        :OpenVipRank.sync="OpenVipRank"
        @getVipRank="getPitchData($event, 'vipRank')"
      ></selectVipRank>
      <!-- 选择会员对话框 -->
      <selectVips
        :OpenVipCards.sync="OpenVipCards"
        @getVipS="getPitchData($event, 'vipItem')"
      ></selectVips>
      <!-- 选择商品对话框 -->
      <selectGoods
        :OpenGoods.sync="OpenGoods"
        @getGoodsIds="getPitchData($event, 'goodsItem')"
        @getGoodsItem="getPitchData($event, 'goodsItem')"
        :isRadio="isSelectGoodsRadio"
      ></selectGoods>
      <!-- 选择商品类别 -->
      <selectCategory
        :OpenCategory.sync="OpenCategory"
        @getCategoryData="getPitchData($event, 'goodsCategory')"
      ></selectCategory>
      <!-- 选择品牌对话框 -->
      <selectBrand
        :OpenBrand.sync="OpenBrand"
        @brandList="getPitchData($event, 'goodsBrand')"
      ></selectBrand>
      <!-- 选择时间日期对话框 -->
      <selectDate
        :OpenDate.sync="OpenDate"
        :DateTimeList="DateTimeList"
        @getDateTimeList="getPitchData($event, 'time')"
      ></selectDate>
      <!-- 选择优惠券对话框 -->
      <selectCoupon
        :OpenCoupon.sync="OpenCoupon"
        @getCoupons="getPitchData($event, 'coupon')"
        :isSuingEditorIsMultiple="true"
      ></selectCoupon>
      <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
    </div>
  </div>
</template>

<script>
import EditTable from "@/components/editTable"; //可编辑表格
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import selectShop from "../../../components/selectShop.vue"; //门店组件
import selectVipRank from "../../../components/selectVipRank.vue"; //会员级别组件
import selectVips from "@/views/components/selectVips"; //会员组件
import selectGoods from "@/views/components/SelectGoods/SelectGoods.vue"; //商品组件
import selectCategory from "../../../components/selectCategory.vue"; //类别组价
import selectBrand from "@/views/components/selectBrand"; //品牌组件
import selectDate from "@/views/components/selectDate"; //时间日期组件
import selectCoupon from "@/views/components/selectCoupon"; //优惠券组件
import Ttable from "./t-table.vue"; //商品表格组件
import comTable from "@/views/components/com-table"; //活动规则表格组件
import activityTable from "./activityTable.vue"; //活动明细表格组件
import { getNewBillNo } from "@/utils/numFormatting"; //获取编号
import {
  savePromo, //新增
  promoDetail, //详情
  updatePromo, //编辑
  updateStatusPromo, //更新方案状态
} from "@/api/marketing/coupon/case"; //接口api
import { listGoods } from "@/api/goods/goods"; //搜索商品接口api
import { CouponList } from "@/api/marketing/coupon/case"; //搜索优惠券接口api
import Dialog from "@/components/Dialog";
import { getDateChangeDetail } from "@/utils/public"; //适用时间转换
import { limitInputlength } from "@/utils";
export default {
  name: "SpecialSchemeDetail",
  dicts: [
    "fill_shop_range_type", //门店范围
    "use_time_range_type", //适用时间范围
    "vip_goods_range_type", //商品范围
    "member_type", //适用会员
    "vip_birthday_type", //会员生日范围
    "coupon_mode", //优惠券形式
    "coupon_case_type", //优惠券类型
  ],
  components: {
    selectShop,
    selectVipRank,
    selectGoods,
    selectCategory,
    Ttable,
    selectBrand,
    selectVips,
    comTable,
    selectDate,
    activityTable,
    selectCoupon,
    topOperatingButton,
    cardTitleCom,
    Dialog,
    EditTable,
  },
  props: {
    //促销方案信息 根据信息渲染促销方案类型
    schemeInfo: {
      type: Object,
    },
  },
  data() {
    //自定义校验规则
    const setoodsNo = (rule, value, callback) => {
      if (value === undefined) {
        callback(new Error("请输入商品编码"));
        this.$message.error("请输入商品编码");
      } else {
        callback();
      }
    };
    return {
      dialogType: "",
      dialogOptions: {
        curType: "",
        title: "选择",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      curTable: "",
      timeDateOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        echoList: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "dateTime",
            label: "时间范围",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "",
            label: "",
            minWidth: 200,
            align: "center",
          },
        ],
      },
      //整体页面加载动画
      boxLoading: false,
      //标题
      title: undefined,
      //禁用状态(已审核/已删除)
      disabled: false,
      //操作按钮状态
      disabledBtn: false,
      //活动规则表格字段
      //门店表格字段
      shopTableLable: [
        { label: "编号", prop: "shopNo" },
        { label: "名称", prop: "shopName" },
      ],
      //会员表格字段
      vipTableLable: [
        { label: "编号", prop: "lineNo" },
        { label: "名称", prop: "lineName" },
      ],
      //时间范围表格字段
      timeTableLable: [{ label: "时间范围", prop: "dateTime" }],
      //活动明细表格字段
      //数量折扣方案表格数据
      ADTableData: [{}],
      //数量折扣方案表格字段
      ADTableLabel: [
        { label: "买满数量", prop: "buyFullValue" },
        { label: "折扣(%)", prop: "discount" },
      ],
      //金额折扣方案表格数据
      MDTableData: [{}],
      //金额折扣方案表格字段
      MDTableLabel: [
        { label: "买满金额", prop: "buyFullValue" },
        { label: "折扣(%)", prop: "discount" },
      ],
      //金额满减方案表格数据
      MFDTableData: [{}],
      //预订优惠方案表格字段
      RDTableLabel: [
        { label: "提前N天", prop: "buyReserveDay" },
        { label: "折扣(%)", prop: "discount" },
      ],
      //金额满减方案表格字段
      MFDTableLabel: [
        { label: "买满金额", prop: "buyFullValue" },
        { label: "减免金额", prop: "giveValue" },
        { label: "最大套数", prop: "maxCount" },
      ],
      //充值免单方案表格数据
      RFTableData: [{}],
      //充值免单方案表格字段
      RFTableLabel: [
        { label: "买满金额", prop: "buyFullValue" },
        { label: "充值倍数", prop: "fillMultiple" },
      ],
      //促销日期 禁用当天之前的时间
      forbiddenTime: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      //日期时间id(方便删除识别)
      timeId: undefined,
      //日期时间累加存放
      specialDate: [],
      // 开启会员表格开关
      vipTable: false,
      //开启会员生日下拉框开关
      showVipBD: false,
      //更新表格视图
      iskey: undefined,
      //选择门店开关
      OpenShop: false,
      //选择会员级别开关
      OpenVipRank: false,
      //选择会员开关
      OpenVipCards: false,
      //选择商品开关
      OpenGoods: false,
      //选择类别开关
      OpenCategory: false,
      //选择品牌开关
      OpenBrand: false,
      //选择时间日期开关
      OpenDate: false,
      //选择优惠券开关
      OpenCoupon: false,
      //是否开启选择商品对话框单选
      isSelectGoodsRadio: false,
      //数量特价方案 参与活动的商品 点击的商品下标
      clickGoodsIndex: 0,
      //固定组合方案/动态组合方案/组合优惠方案 标识下标,用来标识从某个组合点出来的商品选择框 后续好赋值
      signGroupIndex: 0,
      //标识赠送的商品 买满赠送方案 用来标识区分从赠送的商品区域/参与活动的商品表格里点开的选择对话框
      signGoodsTable: undefined,
      //存放初始化时表单数据
      prevForm: {},
      //表单
      form: {
        //基本信息
        billId: undefined, //促销方案id
        billNo: undefined, //促销方案类型编号
        promoType: undefined, //促销方案类型
        promoName: undefined, //促销方案名称
        promoDate: undefined, //促销日期
        begTime: undefined, //促销开始时间
        endTime: undefined, //促销结束时间
        //适用渠道
        AllowOfflineShop: ["0", "1"],
        isAllowOfflineShop: false, //适用渠道(线下门店)
        isAllowOnlineShop: false, //适用渠道(线上商城)
        isAllowVipReagio: false, //是否支持会员折上折
        isAllowTempReagio: false, //是否支持临时折扣折上折
        isAllowCouponReagio: false, //是否支持优惠券折上折
        //活动规则
        shopRangeType: "0", //适用门店
        shopItem: [], //适用门店表格数据
        //发放对象
        vipLevelRangeTypeArr: ["0", "3"], //适用会员数组
        vipLevelItem: [], //适用会员表格数据
        vipBirthdayType: "1", //会员生日时间
        useTimeRangeType: "0", //适用时间
        specialDateItem: [], //适用时间数据
        //参与活动的商品
        goodsRangeType: "0", //适用商品
        goodsItem: [], //适用商品表格数据
        allSpecialPrice: undefined, //商品全部特价
        allSpecialDiscount: undefined, //商品全部折扣
        billRemark: undefined, //备注
      },
      //选择时间日期弹窗数据
      DateTimeList: [
        { specialDateWeekMode: undefined, specialDateWeekNum: undefined },
        { specialDateWeeksArr: [] },
        {
          isTopMonthPeriod: false,
          isDownMonthPeriod: false,
          topFromDay: undefined,
          topToDay: undefined,
          downFromDay: undefined,
          downToDay: undefined,
        },
        { specialDayArr: [] },
        { specialBegTime: undefined, specialEndTime: undefined },
      ], //日期
      //表单校验
      rules: {
        billNo: [{ required: true, message: "请输入方案编号", trigger: "blur" }],
        promoName: [{ required: true, message: "请输入方案名称", trigger: "blur" }],
        promoDate: [{ required: true, message: "请选择促销日期", trigger: "blur" }],
        AllowOfflineShop: [
          { required: true, message: "请选择适用渠道", trigger: "blur" },
        ],
        shopRangeType: [{ required: true, message: "请选择适用门店", trigger: "blur" }],
        vipLevelRangeTypeArr: [
          { required: true, message: "请选择适用对象", trigger: "blur" },
        ],
        useTimeRangeType: [
          { required: true, message: "请选择适用时间", trigger: "blur" },
        ],
        goodsRangeType: [{ required: true, message: "请选择适用商品", trigger: "blur" }],
        "details.goodsNo": [{ validator: setoodsNo, trigger: ["blur", "change"] }],
        allSpecialPrice: [{ required: true, message: "请输入全部特价", trigger: "blur" }],
        allSpecialDiscount: [
          { required: true, message: "请输入全部折扣", trigger: "blur" },
        ],
        // 组合每一项名称
        "comboItem.comboName": [
          { required: true, message: "请输入组合名称", trigger: "blur" },
        ],
        "comboItem.comboPrice": [
          { required: true, message: "请输入组合价格", trigger: "blur" },
        ],
        "comboItem.comboSelectQty": [
          { required: true, message: "请输入数量", trigger: "blur" },
        ],
        buyFullLargessType: [
          { required: true, message: "请选择买满方式", trigger: "blur" },
        ],
        buyLargessType: [{ required: true, message: "请选择买赠方式", trigger: "blur" }],
        buyLargessQty: [{ required: true, message: "请输入赠送数量", trigger: "blur" }],
        "ruleLargessItem.largessRule": [
          { required: true, message: "请选择赠品规则", trigger: "blur" },
        ],
        "ruleLargessItem.discountRate": [
          { required: true, message: "请输入折扣比例", trigger: "blur" },
        ],
        "ruleLargessItem.addMoney": [
          { required: true, message: "请输入加购金额", trigger: "blur" },
        ],
        // 组合优惠方案 总组合名称
        "comboHead.comboHeadName": [
          { required: true, message: "请输入组合名称", trigger: "blur" },
        ],
        "comboHead.discountType": [
          { required: true, message: "请输入优惠方式", trigger: "blur" },
        ],
        "comboHead.discountValue": [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        "buyFullItem.buyFullValue": [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        //固定组合
        "comboItem.comboGoodsItem.lineNo": [
          {
            required: true,
            message: "请选择商品",
            trigger: ["blur", "change"],
          },
        ],
        "comboItem.comboGoodsItem.limitQty": [
          { required: true, message: "请输入数量", trigger: ["blur", "change"] },
        ],
        //固定赠品
        "fixedGoodsItem.lineNo": [
          { required: true, message: "请选择商品", trigger: ["blur", "change"] },
        ],
        "fixedGoodsItem.limitQty": [
          { required: true, message: "请输入数量", trigger: ["blur", "change"] },
        ],
        "goodsLargessItem.limitQty": [
          { required: true, message: "请输入数量", trigger: ["blur", "change"] },
        ],
        //赠送礼券
        "couponLargessItem.couponCaseNo": [
          {
            required: true,
            message: "请选择优惠券",
            trigger: ["blur", "change"],
          },
        ],
        "couponLargessItem.limitQty": [
          { required: true, message: "请输入数量", trigger: ["blur", "change"] },
        ],
      },
      //数量特价方案 点击增加商品框的暂用id
      goodsId: 1,
    };
  },
  watch: {
    //侦听方案类型信息   判断是新增功能或修改功能
    schemeInfo: {
      async handler(info) {
        if (info.type === "add") {
          //新增
          this.reset(info.SchemeId);
          this.form.promoType = info.SchemeId; //方案类型id
          this.title = info.title; //方案名称
          await this.getNewBillNo(this.form, "billNo", "160101");
          //当是数量特价方案时 商品表格数组添加默认一行空数据
          if (this.form.promoType === "1") {
            this.form.goodsRangeType = "2";
            this.form.goodsItem.push({
              goodsId: this.goodsId,
              goodsBuyFullItem: [{}],
            });
          }
          this.timeDateOptions.list = [];
          this.timeDateOptions.echoList = [];
          //给父组件传递初始化form数据
          this.$emit("getForm", this.form);
          //存储初始化的表单数据 后续退出时可判断
          this.prevForm = JSON.stringify(this.form);
        } else {
          //页面加载动画
          this.boxLoading = true;
          //修改
          this.title = info.title; //优惠券方案名称
          const billId = info.billId; //优惠券id
          //初始化数据
          this.reset(info.SchemeId);
          this.getPromoDetail(billId); //根据id获取促销方案详情
        }
      },
    },
    //侦听适用对象
    "form.vipLevelRangeTypeArr": {
      handler(val) {
        if (val) {
          //当选中全部会员+排除/指定会员时 显示会员表格 反之隐藏
          const bool = val.some((v) => v === "1" || v === "2");
          this.vipTable = bool ? true : false;
          //当选中生日会员  显示会员生日下拉框 反之隐藏
          const vipBD = val.some((v) => v === "4");
          this.showVipBD = vipBD ? true : false;
        }
      },
      immediate: true,
    },
    //侦听促销日期
    "form.promoDate": {
      handler(val) {
        if (val) {
          this.form.begTime = val[0];
          this.form.endTime = val[1];
        }
      },
      immediate: true,
    },
    //侦听方案状态 (已审核/已删除)使用禁用
    "form.billStatus": {
      handler(val) {
        if (val === "2" || val === "3") {
          //操作区域禁用
          this.disabled = true;
          //操作按钮（保存按钮/保存并新增按钮禁用,新增按钮显示）
          this.disabledBtn = true;
        } else {
          this.disabled = false;
          this.disabledBtn = false;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.timeId = Date.now(); //获取日期时间识别id
  },
  methods: {
    limitInputlength,
    async handleEvent(type, row) {
      console.log("到这里了嘛弹窗表格数据", type, row);
      if (row?.curTable) this.curTable = row.curTable; //标识当前表格
      console.log(type, "type");
      switch (type) {
        case "openTimeDateDialog":
          this.dialogType = "time";
          //弹窗配置
          this.dialogOptions = {
            curType: "timeDate",
            title: "选择时间日期",
            width: 600,
            show: true,
            type: "TimeDateSelect",
            formData: {
              DateTimeList: this.timeDateOptions.echoList, // 日期
            },
          };
          //适用时间回显
          break;
        case "openCouponDialog":
          //弹窗配置
          this.dialogType = "coupon";
          this.dialogOptions = {
            curTable: "openCouponDialog",
            curType: "couponCaseId", //当前类型(会员/门店/优惠券)
            title: "选择优惠券",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            isGrant: false,
            isNumber: false,
            isStep: true,
            formData: this.$dialog({ key: "coupon" }),
          };
          this.dialogOptions.formData.table.radioSelect = true;
          this.dialogOptions.formData.table.mutiSelect = false;
          break;
        case "dialogChange": //获取弹窗数据
          if (this.dialogType == "time" || this.dialogType == "coupon") {
            if (this.curTable == "openCouponDialog") {
              let list = this.dialogOptions.formData.table.check;
              this.getPitchData(list, "coupon");
              return;
            }
            let list = [];
            this[this.curTable].echoList = this.dialogOptions.formData.DateTimeList; //方便适用时间回显
            console.log(this[this.curTable].echoList, "this[this.curTable].echoList");
            list = await getDateChangeDetail(this.dialogOptions.formData.DateTimeList);
            console.log(list, "list");
            this[this.curTable].list = list;
          } else {
            this.getPitchData(this.dialogOptions.formData.table.check, this.dialogType);
          }
          break;
        default:
          break;
      }
    },
    blurChange(e, index, listIndex) {
      if (!e || e == 0)
        this.form.comboItem[listIndex].comboGoodsItem[index].limitQty = "";
    },
    //获取编号事件
    getNewBillNo,
    // 打开选择门店对话框
    openSelectShop() {
      this.dialogType = "shop";
      this.dialogOptions = {
        title: "选择门店",
        width: 1250,
        show: true,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "shop" }),
      };
    },
    //打开选择会员级别对话框
    openSelectVipRank() {
      this.dialogType = "vipRank";
      this.dialogOptions = {
        title: "选择会员级别",
        width: 1250,
        show: true,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "vipLevel" }),
      };
    },
    //打开选择会员对话框
    openSelectVip() {
      this.dialogType = "vipItem";
      this.dialogOptions = {
        title: "选择会员",
        width: 1250,
        show: true,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "vip" }),
      };
    },
    //根据id获取促销方案详情
    async getPromoDetail(billId) {
      promoDetail(billId).then(async (res) => {
        console.log(res);
        this.form = res.data;
        console.log(this.form, "this.form");
        if (this.form.useTimeRangeType == 2) {
          this.timeDateOptions.list = await getDateChangeDetail(
            this.form.specialDateItem
          );
          this.timeDateOptions.echoList = this.form.specialDateItem;
        }
        if (this.form.promoType == 8 && this.form.buyLargessType == 2) {
          this.form.couponLargessItem = this.form.couponLargessItem.map((item) => ({
            ...item,
            // 延迟生效， 领取2天后生效,生效后有效时长3天
            // 固定日期生效， 2024-03-28 00:00:00,生效后有效时长22天
            couponEffectModeName:
              item.couponEffectMode == 0
                ? `即时生效， 生效后有效时长${item.couponValidDays}天`
                : item.couponEffectMode == 1
                ? `延迟生效， 领取${item.couponEffectDays}天后生效,生效后有效时长${item.couponValidDays}天`
                : `固定日期生效， ${item.couponEffectDate},生效后有效时长${item.couponValidDays}天`,
          }));
        }
        //后端字段转换
        this.transitionField(this.form);
        this.boxLoading = false;
      });
    },
    //打开选择商品对话框   index 主要用于组合方案 标识下标区分某个组合  signGoodsTable主要用于买满赠送方案 标识区分参与活动的商品表格/赠送的商品表格
    openSelectGoods(index, signGoodsTable) {
      //当买满赠送方案时  主要用于买满赠送方案 标识区分参与活动的商品表格/赠送的商品表格
      if (this.form.promoType === "8") {
        this.signGoodsTable = signGoodsTable ? signGoodsTable : undefined;
      }
      if (this.form.promoType === "12") {
        this.curTable = signGoodsTable || "goodsItem";
      }
      //固定组合方案/动态组合方案/组合优惠方案 标识下标,用来标识从某个组合点出来的商品选择框 后续好赋值
      if (index) this.signGroupIndex = index;
      this.dialogType = "goodsItem";
      this.dialogOptions = {
        title: "选择商品",
        width: 1250,
        show: true,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "goods" }),
      };
    },
    //打开选择类别对话框
    openSelectCategory(index, signGoodsTable) {
      //固定组合方案/动态组合方案/组合优惠方案 标识下标,用来标识从某个组合点出来的商品选择框 后续好赋值
      if (this.form.promoType === "8") {
        this.signGoodsTable = signGoodsTable ? signGoodsTable : undefined;
      }
      if (index) this.signGroupIndex = index;
      this.dialogType = "goodsCategory";
      this.dialogOptions = {
        title: "选择类别",
        width: 1250,
        show: true,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "category" }),
      };
    },
    //打开品牌对话框
    openSelectBrand(index, signGoodsTable) {
      //固定组合方案/动态组合方案/组合优惠方案 标识下标,用来标识从某个组合点出来的商品选择框 后续好赋值
      if (this.form.promoType === "8") {
        this.signGoodsTable = signGoodsTable ? signGoodsTable : undefined;
      }
      if (index) this.signGroupIndex = index;
      this.dialogType = "goodsBrand";
      this.dialogOptions = {
        title: "选择品牌",
        width: 1250,
        show: true,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "brand" }),
      };
    },
    //打开时间日期对话框
    openSelectDate() {
      this.OpenDate = true;
    },
    //数量特价-参与活动的商品的列表 点击商品的某一项
    focusGoodsItem(index) {
      this.clickGoodsIndex = index;
    },
    //数量特价-参与活动的商品  添加商品优惠框
    handleAddGoods() {
      this.goodsId = this.goodsId + 1;
      this.form.goodsItem.push({
        goodsId: this.goodsId,
        goodsBuyFullItem: [{}],
      });
    },
    //数量特价-参与活动的商品  添加商品规则
    handleAddRule() {
      this.form.goodsItem[this.clickGoodsIndex].goodsBuyFullItem.push({});
    },
    //数量特价-参与活动的商品  删除商品规则
    handleDelRule(index) {
      if (this.form.goodsItem[this.clickGoodsIndex].goodsBuyFullItem.length <= 1) {
        this.$set(this.form.goodsItem[this.clickGoodsIndex].goodsBuyFullItem, 0, []);
        return;
      }
      this.form.goodsItem[this.clickGoodsIndex].goodsBuyFullItem.splice(index, 1);
    },
    //数量特价-参与活动的商品  删除商品优惠框
    handleDelGoods(index) {
      if (this.form.goodsItem.length <= 1) {
        this.$set(this.form.goodsItem, index, {
          goodsId: this.goodsId,
          goodsBuyFullItem: [{}],
        });
        return;
      }
      if (this.clickGoodsIndex === index) this.clickGoodsIndex = index - 1; //高亮的下标等于要删除的商品框时,高亮下标减1
      this.form.goodsItem.splice(index, 1);
    },
    //参与活动的商品 单位改变零售价跟着变  list 商品数组 units单位数组 unitId单位id
    changeUnitPrice(list, units, unitId) {
      units.forEach((v) => {
        if (v.unitId === unitId) {
          this.$set(list, "salePrice", v.salePrice);
          this.$set(list, "goodsSpec", v.goodsSpec);
        }
      });
    },
    //表格增加/减少一行方法  list数组 type类型(增加/减少) index下标
    tableRow(list, type, index) {
      if (type === "push") {
        list.splice(index + 1, 0, {});
      } else {
        if (list.length <= 1) {
          this.$set(list, index, {});
          return;
        }
        list.splice(index, 1);
      }
    },
    //固定组合方案/动态组合方案/组合优惠方案 组合增加一项减少一项 type类型(增加/减少) index下标
    handleRow(type, index) {
      if (type === "push") {
        this.form.comboItem.splice(index + 1, 0, { comboGoodsItem: [{}] });
      } else {
        if (this.form.comboItem.length <= 1) {
          this.$set(this.form.comboItem, index, { comboGoodsItem: [{}] });
          return;
        }
        this.form.comboItem.splice(index, 1);
      }
    },
    //活动明细表格增加一行减少一行
    DetailTableRow({ type, index }) {
      if (type === "push") {
        this.form.buyFullItem.splice(index + 1, 0, {
          buyFullValue: undefined, //买满金额/买满数量
          giveValue: undefined, //赠送金额/减免金额/赠送数量
          discountType: "0", //优惠方式
          fillMultiple: undefined, //充值倍数
        });
      } else {
        if (this.form.buyFullItem.length <= 1) {
          this.$set(this.form.buyFullItem, index, {
            buyFullValue: undefined, //买满金额/买满数量
            giveValue: undefined, //赠送金额/减免金额/赠送数量
            discountType: "0", //优惠方式
            fillMultiple: undefined, //充值倍数
          });
          return;
        }
        this.form.buyFullItem.splice(index, 1);
      }
    },
    //商品表格回车事件 event.target.value 拿到输入的值  index 当时组合方案时,标识哪个组合的下标
    goodsKeyupEnter(event, index) {
      //判断是否输入值
      if (event.target.value) {
        listGoods({
          goodsNo: event.target.value,
        }).then(async (response) => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            event.target.blur();
            //当是组合方案时,标识哪个组合
            if (this.form.promoType === "2" || this.form.promoType === "10") {
              this.signGroupIndex = index;
            }
            //当是买满赠送时,标识商品表格
            if (this.form.promoType === "8") {
              this.signGoodsTable = "giveGoodsTable";
            }
            //字段转换并赋值
            this.getPitchData(response.rows, "goodsItem");
          } else {
            //反之直接弹出商品信息框
            this.OpenGoods = true;
          }
        });
      } else {
        //没输入直接弹出商品信息框
        event.target.blur();
        this.OpenGoods = true;
      }
    },
    //优惠券表格回车事件 event.target.value 拿到输入的值   index 优惠券表格数组的下标
    couponKeyupEnter(event, index) {
      //判断是否输入值
      if (event.target.value) {
        CouponList({
          query: event.target.value,
        }).then(async (response) => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            this.$set(this.form.couponLargessItem, index, response.rows[0]);
          } else {
            //反之直接弹出商品信息框
            this.OpenCoupon = true;
          }
        });
      } else {
        //没输入直接弹出商品信息框
        event.target.blur();
        this.OpenCoupon = true;
      }
    },
    //选中适用对象改变触发(选中全部会员去除指定会员,选中指定会员去除全部会员)
    handleCheckVip() {
      if (this.form.vipLevelRangeTypeArr.length > 1) {
        // 拿到最后一个选择的数据  要以最后一个选择的为判断
        if (
          this.form.vipLevelRangeTypeArr[this.form.vipLevelRangeTypeArr.length - 1] ===
          "2"
        ) {
          //当最后一个数据是指定会员 去除全部会员/全部会员+排除
          this.form.vipLevelRangeTypeArr = this.form.vipLevelRangeTypeArr.filter(
            (v) => v !== "0" && v !== "1"
          );
        } else if (
          this.form.vipLevelRangeTypeArr[this.form.vipLevelRangeTypeArr.length - 1] ===
          "0"
        ) {
          //当最后一个数据是全部会员 去除指定会员/全部会员+排除/会员生日
          this.form.vipLevelRangeTypeArr = this.form.vipLevelRangeTypeArr.filter(
            (v) => v !== "2" && v !== "4" && v !== "1"
          );
        } else if (
          this.form.vipLevelRangeTypeArr[this.form.vipLevelRangeTypeArr.length - 1] ===
          "1"
        ) {
          //当最后一个数据是全部会员+排除 去除指定会员/全部会员/会员生日
          this.form.vipLevelRangeTypeArr = this.form.vipLevelRangeTypeArr.filter(
            (v) => v !== "2" && v !== "4" && v !== "0"
          );
        } else if (
          this.form.vipLevelRangeTypeArr[this.form.vipLevelRangeTypeArr.length - 1] ===
          "4"
        ) {
          //当最后一个数据是会员生日 去除全部会员/全部会员+排除
          this.form.vipLevelRangeTypeArr = this.form.vipLevelRangeTypeArr.filter(
            (v) => v !== "1"
          );
        }
      }
    },
    //选中指定时间转换
    //日期时间转换详情
    // getDateChangeDetail(value) {
    //     // let Arr = []
    //     this.specialDate = []
    //     value.forEach(v => {
    //         //声明属性
    //         // 第一行
    //         let weekText1
    //         if (v.specialDateWeekMode) {
    //             if (v.specialDateWeekMode === '0') {
    //                 weekText1 = '仅单周'
    //             } else if (v.specialDateWeekMode === '1') {
    //                 weekText1 = '仅双周'
    //             } else if (v.specialDateWeekMode === '2') {
    //                 weekText1 = `仅每月第${v.specialDateWeekNum}周`
    //             }
    //             this.timeId = this.timeId + 1
    //             const id = this.timeId + ''
    //             this.$set(v, 'dateTime', weekText1) //dateTime 日期时间共用字段
    //             this.$set(v, 'id', id)
    //             this.$set(v, 'specialDateType', 0) //日期每一行类型
    //             this.specialDate.push(v)
    //         } else if (v.specialDateWeeksArr && v.specialDateWeeksArr.length > 0) {
    //             // 第二行  (星期转换文字)
    //             let array = [...v.specialDateWeeksArr]
    //             for (let index = 0; index < array.length; index++) {
    //                 if (array[index] === '1') {
    //                     array[index] = '星期一'
    //                 } else if (array[index] === '2') {
    //                     array[index] = '星期二'
    //                 } else if (array[index] === '3') {
    //                     array[index] = '星期三'
    //                 } else if (array[index] === '4') {
    //                     array[index] = '星期四'
    //                 } else if (array[index] === '5') {
    //                     array[index] = '星期五'
    //                 } else if (array[index] === '6') {
    //                     array[index] = '星期六'
    //                 } else if (array[index] === '7') {
    //                     array[index] = '星期日'
    //                 }
    //             }
    //             let val = array.toString()
    //             this.timeId = this.timeId + 2
    //             const id = this.timeId + ''
    //             this.$set(v, 'dateTime', val)
    //             this.$set(v, 'id', id)
    //             this.$set(v, 'specialDateType', 1)
    //             this.specialDate.push(v)
    //         } else if (v.isTopMonthPeriod && v.isDownMonthPeriod) {
    //             // 第三行
    //             const val = `仅每月第${v.topFromDay}至${v.topToDay}天,每月最后${v.downFromDay}至${v.downToDay}天`
    //             this.timeId = this.timeId + 3
    //             const id = this.timeId + ''
    //             this.$set(v, 'dateTime', val)
    //             this.$set(v, 'id', id)
    //             this.$set(v, 'specialDateType', 2)
    //             this.specialDate.push(v)
    //         } else if (v.isTopMonthPeriod) {
    //             const val = `仅每月第${v.topFromDay}至${v.topToDay}天`
    //             this.timeId = this.timeId + 3
    //             const id = this.timeId + ''
    //             this.$set(v, 'dateTime', val)
    //             this.$set(v, 'id', id)
    //             this.$set(v, 'specialDateType', 2)
    //             this.specialDate.push(v)
    //         } else if (v.isDownMonthPeriod) {
    //             const val = `每月最后${v.downFromDay}至${v.downToDay}天`
    //             this.timeId = this.timeId + 3
    //             const id = this.timeId + ''
    //             this.$set(v, 'dateTime', val)
    //             this.$set(v, 'id', id)
    //             this.$set(v, 'specialDateType', 2)
    //             this.specialDate.push(v)
    //         } else if (v.specialDayArr && v.specialDayArr.length > 0) {
    //             // 第四行
    //             v.specialDayArr.sort() //进行排序
    //             let val = ''
    //             //转换number类型,进行排序
    //             v.specialDayArr.forEach(i => {
    //                 val += i + ','
    //             })
    //             val = val.slice(0, val.length - 1)
    //             val = `指定日期: 每月${val}日`
    //             this.timeId = this.timeId + 4
    //             const id = this.timeId + ''
    //             this.$set(v, 'dateTime', val)
    //             this.$set(v, 'id', id)
    //             this.$set(v, 'specialDateType', 3)
    //             this.specialDate.push(v)
    //         } else if (v.specialBegTime) {
    //             // 第五行
    //             const val = `时间范围：${v.specialBegTime}--${v.specialEndTime}`
    //             this.timeId = this.timeId + 5
    //             const id = this.timeId + ''
    //             this.$set(v, 'dateTime', val)
    //             this.$set(v, 'id', id)
    //             this.$set(v, 'specialDateType', 4)
    //             this.specialDate.push(v)
    //         }
    //     })
    //     return this.specialDate
    // },
    //获取选中的数据赋值表格    type区分类型(门店/商品/会员/时间/优惠券)
    getPitchData(data, type) {
      console.log(data);
      console.log(data, type);
      console.log(this.form.promoType, "this.form.promoType");

      console.log(data, type);
      let tableData = []; //定义一个数组变量 (存放不同表格)
      let id; //定义一个变量存放不同id名称
      if (type === "shop") {
        //门店数据
        tableData = this.form.shopItem; //使用引入类型 数组是栈类型 直接赋值栈的地址不变
        id = "shopId"; //门店id名称
      } else if (
        //(商品/商品类别/商品品牌)
        type === "goodsItem" ||
        type === "goodsCategory" ||
        type === "goodsBrand"
      ) {
        //当是固定组合方案/动态组合方案/组合优惠方案 过滤掉组合里商品表格中空的商品行 商品数组是comboGoodsItem
        if (
          this.form.promoType === "2" ||
          this.form.promoType === "3" ||
          this.form.promoType === "10"
        ) {
          if (["2", "10"].includes(this.form.promoType)) {
            this.form.comboItem[this.signGroupIndex].comboGoodsItem = this.form.comboItem[
              this.signGroupIndex
            ].comboGoodsItem.filter((v) => v.goodsId);
          }
          tableData = this.form.comboItem[this.signGroupIndex].comboGoodsItem;
        } else if (
          this.form.promoType === "8" &&
          this.signGoodsTable === "giveGoodsTable" &&
          this.form.buyLargessType === "0"
        ) {
          //当是买满赠送方案并且从赠送商品的任选N件的商品表格点开，获取的商品数据 商品数组是optionalGoodsItem
          //过滤掉空的表格行
          tableData = this.form.optionalGoodsItem;
        } else if (
          this.form.promoType === "8" &&
          this.signGoodsTable === "giveGoodsTable" &&
          this.form.buyLargessType === "1"
        ) {
          //当是买满赠送方案并且从赠送商品的固定赠品的商品表格点开，获取的商品数据 商品数组是fixedGoodsItem
          //过滤掉空的表格行
          this.form.fixedGoodsItem = this.form.fixedGoodsItem.filter((v) => v.lineId);
          tableData = this.form.fixedGoodsItem;
        } else if (this.form.promoType === "12") {
          this.form[this.curTable] = this.form[this.curTable].filter((v) => v.lineId);
          tableData = this.form[this.curTable];
        } else {
          //其他方案的商品数组是goodsItem
          //商品数组
          tableData = this.form.goodsItem;
        }
        id = "lineId"; //(商品/商品类别/商品品牌)公用id
      } else if (type === "vipItem" || type === "vipRank") {
        //(会员/会员级别)
        tableData = this.form.vipLevelItem;
        id = "lineId"; //(会员/会员级别)公用id
      } else if (type === "time") {
        tableData = getDateChangeDetail(data); //日期时间转换
        this.form.specialDateItem = [...tableData]; //日期时间直接赋值 不需要过滤相同的
        this.DateTimeList = data;
        return;
      } else if (type === "coupon") {
        //优惠券
        //过滤掉空的表格行
        this.form.couponLargessItem = this.form.couponLargessItem.filter(
          (v) => v.couponCaseId
        );
        tableData = this.form.couponLargessItem;
        id = "couponCaseId";
      }
      //过滤选中数组跟表格里重复的数据
      if (tableData.length >= 1) {
        //当表格数据存在时
        for (let i = 0; tableData.length > i; i++) {
          data = data.filter((v) => {
            //商品表格/会员表格需要添加上类型
            if (type === "goodsItem") {
              //商品
              v.lineType = 1; //商品表格行统一类型
              v.lineId = v.goodsId; //商品表格行统一id
              v.lineNo = v.goodsNo; //统一编号字段
              v.lineName = v.goodsName; //统一名称字段
              if (this.form.promoType === "3") this.$set(v, "limitQty", ""); //当是动态组合方案时,初始化数量为0,0代表不限量
            } else if (type === "goodsCategory") {
              //商品类别
              v.lineType = 3;
              v.lineNo = v.categoryNo; //字段转换成商品表格一样的字段
              v.lineName = v.categoryName;
              v.lineId = v.categoryId;
              if (this.form.promoType === "3") this.$set(v, "limitQty", ""); //当是动态组合方案时,初始化数量为0,0代表不限量
            } else if (type === "goodsBrand") {
              //商品品牌
              v.lineType = 2;
              v.lineNo = v.brandNo; //字段转换成商品表格一样的字段
              v.lineName = v.brandName;
              v.lineId = v.brandId;
              if (this.form.promoType === "3") this.$set(v, "limitQty", ""); //当是动态组合方案时,初始化数量为0,0代表不限量
            } else if (type === "vipItem") {
              //会员
              v.lineType = 1;
              v.lineId = v.vipId; //会员表格行统一id
              v.lineNo = v.vipNo; //统一编号字段
              v.lineName = v.vipName; //统一名称字段
            } else if (type === "vipRank") {
              //会员级别
              v.lineType = 2;
              v.lineId = v.vipLevelId;
              v.lineNo = v.vipLevelNo; //统一编号字段
              v.lineName = v.vipLevelName; //统一名称字段
            } else if (type === "coupon") {
              v = this.assemblyCoupon(v);
            }
            if (type !== "goodsItem") {
              return v[id] !== tableData[i][id]; //选中的数据(门店/商品/会员) 过滤掉已经在表格中已经存在的数据
            } else {
              return v;
            }
          });
        }
        //当是数量特价方案时,选择商品是单选,直接赋值,并添加上一行空规则数组
        if (this.form.promoType === "1") {
          console.log(data);
          this.$set(this.form.goodsItem, this.clickGoodsIndex, data[0]);
          return this.$set(
            this.form.goodsItem[this.clickGoodsIndex],
            "goodsBuyFullItem",
            [{}]
          );
        }
        tableData.push(...data); //再追加在表格里
      } else {
        //当表格数据为空时,直接追加表格里
        data.forEach((v) => {
          //商品表格/会员表格需要添加上类型
          if (type === "goodsItem") {
            //商品
            v.lineType = 1; //商品表格行统一类型
            v.lineId = v.goodsId; //商品表格行统一id
            v.lineNo = v.goodsNo; //统一编号字段
            v.lineName = v.goodsName; //统一名称字段
            if (this.form.promoType === "3") this.$set(v, "limitQty", ""); //当是动态组合方案时,初始化数量为0,0代表不限量
          } else if (type === "goodsCategory") {
            //商品类别
            v.lineType = 3;
            v.lineNo = v.categoryNo; //字段转换成商品表格一样的字段
            v.lineName = v.categoryName;
            v.lineId = v.categoryId;
            if (this.form.promoType === "3") this.$set(v, "limitQty", ""); //当是动态组合方案时,初始化数量为0,0代表不限量
          } else if (type === "goodsBrand") {
            //商品品牌
            v.lineType = 2;
            v.lineNo = v.brandNo; //字段转换成商品表格一样的字段
            v.lineName = v.brandName;
            v.lineId = v.brandId;
            if (this.form.promoType === "3") this.$set(v, "limitQty", ""); //当是动态组合方案时,初始化数量为0,0代表不限量
          } else if (type === "vipItem") {
            //会员
            v.lineType = 1;
            v.lineId = v.vipId; //会员表格行统一id
            v.lineNo = v.vipNo; //统一编号字段
            v.lineName = v.vipName; //统一名称字段
          } else if (type === "vipRank") {
            //会员级别
            v.lineType = 2;
            v.lineId = v.vipLevelId;
            v.lineNo = v.vipLevelNo; //统一编号字段
            v.lineName = v.vipLevelName; //统一名称字段
          } else if (type === "coupon") {
            v = this.assemblyCoupon(v);
          }
          console.log(v);
          tableData.push(v);
          console.log(tableData);
          console.log(this.form.fixedGoodsItem);
        });
      }
    },
    //组装优惠券生效方式转换
    assemblyCoupon(value) {
      //生效方式
      const couponEffectModes = {
        0: "即时生效， ",
        1: "延迟生效， ",
        2: "固定日期生效， ",
      };
      const {
        couponEffectMode,
        couponEffectDays,
        couponEffectDate,
        couponValidDays,
      } = value;
      let couponEffectModeName = couponEffectModes[couponEffectMode];
      if (couponEffectMode === 0) {
        couponEffectModeName += `生效后有效时长${couponValidDays}天`;
      } else if (couponEffectMode === 1) {
        couponEffectModeName += `领取${couponEffectDays}天后生效,生效后有效时长${couponValidDays}天`;
      } else if (couponEffectMode === 2) {
        couponEffectModeName += `${couponEffectDate},生效后有效时长${couponValidDays}天`;
      }
      value.couponEffectModeName = couponEffectModeName;
      return value;
    },
    //删除表格行事件(共同删除事件,以type区分表格(门店/商品/会员/时间))
    delTableRow(row, type, index) {
      if (type === "shop") {
        //门店
        //过滤掉删除的数据行
        this.form.shopItem = this.form.shopItem.filter((v) => v.shopId !== row.shopId);
      } else if (type === "comboGoodsItem") {
        this.form.comboItem[index].comboGoodsItem = this.form.comboItem[
          index
        ].comboGoodsItem.filter((v) => v.lineId !== row.lineId);
      } else if (type === "optionalGoodsItem") {
        this.form.optionalGoodsItem.splice(row.rowIndex, 1);
      } else if (type === "goods") {
        //商品
        //过滤掉删除的数据行
        this.form.goodsItem = this.form.goodsItem.filter((v) => v.lineId !== row.lineId);
      } else if (type === "vip") {
        //会员
        //过滤掉删除的数据行
        this.form.vipLevelItem = this.form.vipLevelItem.filter(
          (v) => v.lineId !== row.lineId
        );
      } else if (type === "time") {
        //时间
        //过滤掉删除的数据行
        this.specialDate = this.specialDate.filter((v) => v.id !== row.id); //累加的时间数组
        this.form.specialDateItem = this.form.specialDateItem.filter(
          (v) => v.id !== row.id
        );
      }
    },
    //保存前的校验/转换
    verifyScheme() {
      //校验选中全部门店+排除/指定门店 表格里是否选择门店
      if (this.form.shopRangeType === "1" || this.form.shopRangeType === "2") {
        if (this.form.shopItem.length <= 0) {
          return this.$message.error("请选择门店");
        }
      }
      //校验选中全部商品+排除/指定商品  表格里是否选择商品
      if (
        this.form.goodsRangeType === "1" ||
        this.form.goodsRangeType === "2" ||
        (this.form.deductionGoodsType === "0" && this.form.couponCaseType === "2") ||
        (this.form.deductionGoodsType === "1" && this.form.couponCaseType === "2")
      ) {
        if (this.form.goodsItem.length <= 0) {
          return this.$message.error("请选择商品");
        }
      }
      //校验选中全部会员+排除/指定会员    表格里是否选择会员
      if (this.vipTable) {
        if (this.form.vipLevelItem.length <= 0) {
          return this.$message.error("请选择会员");
        }
      }
      //校验指定时间    表格里是否选择时间
      if (this.form.useTimeRangeType === "2") {
        if (this.form.specialDateItem.length <= 0) {
          return this.$message.error("请选择时间");
        }
      }
      //校验选中全部商品+排除/指定商品  表格里是否选择商品
      if (
        this.form.goodsRangeType === "1" ||
        this.form.goodsRangeType === "2" ||
        (this.form.deductionGoodsType === "0" && this.form.couponCaseType === "2") ||
        (this.form.deductionGoodsType === "1" && this.form.couponCaseType === "2")
      ) {
        if (this.form.goodsItem.length <= 0) {
          return this.$message.error("请选择商品");
        }
      }
      //数量特价方案 过滤空的商品框 校验商品是否输入了完整优惠规则 没有输入弹出提示
      if (this.form.promoType === "1") {
        //过滤空的商品框
        if (this.form.goodsItem.length <= 1) {
          const bool = this.form.goodsItem[0].lineId ? true : false;
          if (!bool) return this.message.error("请选择商品");
        } else {
          this.form.goodsItem = this.form.goodsItem.filter((item) => item.lineId);
        }
        console.log(this.form.goodsItem);
        //并初始化选中的商品下标为第一个
        this.clickGoodsIndex = 0;
        //定义一个数组,存放优惠规则没有输入完整的商品名称
        let goodsNames = [];
        this.form.goodsItem.map((v) => {
          //过滤商品里优惠规则里为空的一行
          let newGoodsBuyFullItem = [];
          //当只有一行规则时,不需要过滤直接赋值
          if (v.goodsBuyFullItem.length <= 1) {
            newGoodsBuyFullItem.push(v.goodsBuyFullItem[0]);
          } else {
            for (let i = 0; v.goodsBuyFullItem.length > i; i++) {
              //数量/特价都为空的一行过滤掉
              const bool =
                !v.goodsBuyFullItem[i].buyFullQty && !v.goodsBuyFullItem[i].specialPrice;
              if (!bool) newGoodsBuyFullItem.push(v.goodsBuyFullItem[i]);
            }
          }
          //过滤掉一行优惠规则都为空后剩下的数组重新赋值
          this.$set(v, "goodsBuyFullItem", newGoodsBuyFullItem);
          //优惠规则 第一行为空/其他行数量为空或特价为空的时候 把商品名称提取出来 拼接成字符串弹出提示
          const boll = v.goodsBuyFullItem.every((i) => i.buyFullQty && i.specialPrice);
          if (!boll) goodsNames.push(v.goodsName);
        });
        if (goodsNames.length >= 1) {
          return this.$message.error(`请输入${goodsNames.join()}商品完整的优惠规则`);
        }
      }
      //固定组合方案/动态组合方案/组合优惠方案 过滤空的商品表格行 固定组合方案校验数量是否输入
      if (
        this.form.promoType === "2" ||
        this.form.promoType === "3" ||
        this.form.promoType === "10"
      ) {
        let bool = true; //校验是否成功 默认是true
        this.form.comboItem.map((v) => {
          // 当只有一行时校验是否有lineId
          if (v.comboGoodsItem.length === 1) {
            bool = v.comboGoodsItem.some((i) => i.lineId);
          } else {
            v.comboGoodsItem = v.comboGoodsItem.filter((j) => j.lineId);
            if (v.comboGoodsItem.length <= 0) {
              //当是动态组合方案时, 商品表格数据 不需要初始化第一行 其他组合需要初始化有第一行
              if (this.form.promoType === "3") {
                v.comboGoodsItem = [];
              } else {
                v.comboGoodsItem = [{}];
              }
              bool = false;
            }
          }
        });
        if (!bool) return this.$message.error("请选择商品");
      }
      //当是买满赠送方案时，赠送的商品 判断选中的买赠方式 商品表格字段转换
      if (this.form.promoType === "8") {
        if (this.form.buyLargessType === "0") {
          //任选N件
          //校验商品表格是否为空
          if (this.form.optionalGoodsItem.length === 0) {
            return this.$message.error("请选择商品");
          }
          this.form.goodsLargessItem = this.form.optionalGoodsItem;
        }
        if (this.form.buyLargessType === "1") {
          this.form.goodsLargessItem = this.form.fixedGoodsItem;
        }
        //买赠方式不为赠送礼券时 初始化数据
        if (!(this.form.buyLargessType === "2")) {
          this.form.couponLargessItem = [];
        }
        //判断买满赠送方案 处理买赠方式的表格的空对象
        if (this.form.buyLargessType == "0") {
          this.form.couponLargessItem = [];
        } else if (this.form.buyLargessType == "1") {
          this.form.couponLargessItem = [];
        } else if (this.form.buyLargessType == "2") {
          this.form.goodsLargessItem = [];
        } else if (this.form.buyLargessType == "3") {
          this.form.goodsLargessItem = [];
          this.form.couponLargessItem = [];
          this.form.fixedGoodsItem = [];
        }
      }
      this.form.isAllowOfflineShop = false;
      this.form.isAllowOnlineShop = false;
      if (this.form.AllowOfflineShop.includes("0")) {
        this.form.isAllowOfflineShop = true;
      }
      if (this.form.AllowOfflineShop.includes("1")) {
        this.form.isAllowOnlineShop = true;
      }
      //到这里证明校验成功
      return true;
    },
    //从后端获取的数据进行转换
    transitionField(data) {
      //适用渠道转换
      this.$set(this.form, "AllowOfflineShop", []);
      if (data.isAllowOfflineShop) this.form.AllowOfflineShop.push("0");
      if (data.isAllowOnlineShop) this.form.AllowOfflineShop.push("1");
      //促销时间时间转换
      this.$set(this.form, "promoDate", []);
      this.form.promoDate.push(data.begTime);
      this.form.promoDate.push(data.endTime);
      //日期时间表格转换
      if (data.specialDateItem && data.specialDateItem.length > 0) {
        getDateChangeDetail(this.form.specialDateItem);
      }
      //当是买满赠送方案时，赠送的商品 判断选中的买赠方式 商品表格字段转换
      if (data.promoType === "8") {
        if (data.buyLargessType === "0") {
          this.form.optionalGoodsItem = this.form.goodsLargessItem;
        } else {
          this.form.optionalGoodsItem = [];
        }
        if (this.form.buyLargessType === "1") {
          this.form.fixedGoodsItem = this.form.goodsLargessItem;
        } else {
          this.form.fixedGoodsItem = [
            {
              lineNo: "",
              lineName: "",
              goodsSpec: "",
              units: [],
              limitQty: "45654654",
              promoPrice: "",
            },
          ];
        }
        if (this.form.buyLargessType === "2") {
          this.form.couponLargessItem = this.form.couponLargessItem
            ? this.form.couponLargessItem
            : [{}];
        } else {
          this.form.couponLargessItem = [{}];
        }
        //判断买满赠送方案 新增买赠方式的表格的空对象
        //  this.form.goodsLargessItem = [{}]
        //  this.form.fixedGoodsItem = [{}]
      }
      if (data.promoType === "11") {
        this.form.buyFullItem = this.form.reserveDayItem;
      }
    },
    //保存按钮 保存并新增按钮  bool区分保存/保存并新增 Audit区分从审核按钮点击进行的提交保存,不弹出提示
    submitForm(bool, Audit) {
      if (this.form.promoType == 0 && this.form.goodsRangeType == 2) {
        let findIndex = this.form.goodsItem.findIndex((item) => !item.promoPrice);
        if (findIndex != -1) return this.$message.error("请输入必填项");
        console.log(findIndex, "form.findIndex");
      }
      if (this.form.promoType == 4 && this.form.goodsRangeType == 2) {
        let findIndex = this.form.goodsItem.findIndex((item) => !item.discount);
        console.log(findIndex, "form.findIndex");
        if (findIndex != -1) return this.$message.error("请输入必填项");
      }
      if (this.form.useTimeRangeType == 2) {
        if (this.timeDateOptions.list.length == 0) {
          return this.$message.error("请选择时间");
        } else {
          this.form.specialDateItem = this.timeDateOptions.list;
        }
      }
      if (this.form.useTimeRangeType == 2) {
        if (this.timeDateOptions.list.length == 0) {
          return this.$message.error("请选择时间");
        } else {
          this.form.specialDateItem = this.timeDateOptions.list;
        }
      }
      // if(this.form.buyFullLargessType == '0'){
      //     this.form.buyFullMoneyType = undefined
      // }else{
      //     this.form.buyFullQtyType = undefined
      // }
      if (!["7", 7, "6", 6].includes(this.form.promoType)) {
        if (this.form.buyFullLargessType == "0") {
          this.form.buyFullMoneyType = undefined;
        } else {
          this.form.buyFullQtyType = undefined;
        }
      }
      if (this.form.promoType === "11") {
        this.form.reserveDayItem = this.form.buyFullItem;
      }
      return new Promise((resolve) => {
        this.$refs["form"].validate(async (valid) => {
          //表单校验
          if (valid) {
            //提交前的校验/字段转换  为true才校验成功
            const res = this.verifyScheme();
            if (!(res === true)) return;
            let tipNama; //提交成功后的提示文本
            if (this.form.billId != undefined) {
              //修改事件
              const res = await updatePromo(this.form);
              this.form = res.data;
              if (this.form.promoType == 8 && this.form.buyLargessType == 2) {
                this.form.couponLargessItem = this.form.couponLargessItem.map((item) => ({
                  ...item,
                  // 延迟生效， 领取2天后生效,生效后有效时长3天
                  // 固定日期生效， 2024-03-28 00:00:00,生效后有效时长22天
                  couponEffectModeName:
                    item.couponEffectMode == 0
                      ? `即时生效， 生效后有效时长${item.couponValidDays}天`
                      : item.couponEffectMode == 1
                      ? `延迟生效， 领取${item.couponEffectDays}天后生效,生效后有效时长${item.couponValidDays}天`
                      : `固定日期生效， ${item.couponEffectDate},生效后有效时长${item.couponValidDays}天`,
                }));
              }
              //后端字段转换
              this.transitionField(this.form);
              tipNama = "修改方案成功";
              resolve(res.data.billId);
            } else {
              //新增事件
              const res = await savePromo(this.form);
              this.form = res.data;
              //后端字段转换
              if (this.form.promoType == 8 && this.form.buyLargessType == 2) {
                this.form.couponLargessItem = this.form.couponLargessItem.map((item) => ({
                  ...item,
                  // 延迟生效， 领取2天后生效,生效后有效时长3天
                  // 固定日期生效， 2024-03-28 00:00:00,生效后有效时长22天
                  couponEffectModeName:
                    item.couponEffectMode == 0
                      ? `即时生效， 生效后有效时长${item.couponValidDays}天`
                      : item.couponEffectMode == 1
                      ? `延迟生效， 领取${item.couponEffectDays}天后生效,生效后有效时长${item.couponValidDays}天`
                      : `固定日期生效， ${item.couponEffectDate},生效后有效时长${item.couponValidDays}天`,
                }));
              }
              this.transitionField(this.form);
              tipNama = "新增方案成功";
              resolve(res.data.billId);
            }
            //从审核按钮进去的保存
            if (Audit) return;
            //保存并新增
            if (bool) {
              //方案类型
              const promoType = this.form.promoType;
              //初始化数据
              this.reset(promoType);
              this.form.promoType = promoType;
              this.$message.success("保存并新增成功");
              this.getNewBillNo(this.form, "billNo", "160201");
            } else {
              //新增/修改的提示
              this.$message.success(tipNama);
            }
          } else {
            this.$message.error("请把带有红星的必填项输入完整!");
          }
        });
      });
    },
    //审核/反审核按钮 type 0是反审核 2是审核
    async submitAudit(type) {
      let billIds = this.form.billId;
      //当id为空时,先保存再审核
      if (!billIds) billIds = await this.submitForm(false, true);
      let typeName = type === "反审核" ? "反审核成功" : "审核成功";
      if (type === "审核" && this.form.billStatus != "0")
        return this.$message.error("此单据不是未审核的状态哦~");
      if (type === "反审核" && this.form.billStatus != "2")
        return this.$message.error("此单据不是已审核的状态哦~");
      updateStatusPromo({
        billIds: [billIds],
        billStatus: type === "反审核" ? "0" : "2",
      }).then((res) => {
        this.form = res.data;
        if (this.form.promoType == 8 && this.form.buyLargessType == 2) {
          this.form.couponLargessItem = this.form.couponLargessItem.map((item) => ({
            ...item,
            // 延迟生效， 领取2天后生效,生效后有效时长3天
            // 固定日期生效， 2024-03-28 00:00:00,生效后有效时长22天
            couponEffectModeName:
              item.couponEffectMode == 0
                ? `即时生效， 生效后有效时长${item.couponValidDays}天 `
                : item.couponEffectMode == 1
                ? `延迟生效， 领取${item.couponEffectDays}天后生效,生效后有效时长${item.couponValidDays}天`
                : `固定日期生效， ${item.couponEffectDate},生效后有效时长${item.couponValidDays}天`,
          }));
        }
        //后端字段转换
        this.transitionField(this.form);
        this.$message.success(typeName);
      });
    },
    //新增事件 新增按钮
    addScheme() {
      //方案类型
      const schemeId = this.form.promoType;
      this.reset(schemeId);
      this.form.promoType = schemeId;
      this.getNewBillNo(this.form, "billNo", "160201");
    },
    //退出事件 退出按钮
    quitScheme() {
      if (JSON.stringify(this.form) !== this.prevForm) {
        //form数据修改过,提示用户是否保存
        this.$confirm("方案内容已经修改, 请问是否保存该方案?", "提示", {
          confirmButtonText: "保存",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            await this.submitForm();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消保存",
            });
          });
      }
      this.$router.push("/marketing/promo/specialprice/components/SpecialScheme");
    },
    //重置初始化数据
    reset(id) {
      //表单
      this.form = {
        //基本信息
        billId: undefined, //促销方案id
        billNo: undefined, //促销方案类型编号
        promoType: undefined, //促销方案类型
        promoName: undefined, //促销方案名称
        promoDate: undefined, //促销日期
        begTime: undefined, //促销开始时间
        endTime: undefined, //促销结束时间
        //适用渠道
        AllowOfflineShop: ["0", "1"],
        isAllowOfflineShop: false, //适用渠道(线下门店)
        isAllowOnlineShop: false, //适用渠道(线上商城)
        //活动规则
        shopRangeType: "0", //适用门店
        shopItem: [], //适用门店表格数据
        //发放对象
        vipLevelRangeTypeArr: ["0", "3"], //适用会员数组
        vipLevelItem: [], //适用会员表格数据
        vipBirthdayType: "1", //会员生日时间
        useTimeRangeType: "0", //适用时间
        specialDateItem: [], //适用时间数据
        //参与活动的商品
        goodsRangeType: "0", //适用商品
        goodsItem: [], //适用商品表格数据
        allSpecialPrice: undefined, //商品全部特价
        allSpecialDiscount: undefined, //商品全部折扣
        billRemark: undefined, //备注
      };
      //适用时间累加数据
      this.specialDate = [];
      //方案类型
      const SchemeId = id;
      //当是数量特价方案时,选择商品对话框时单选状态 反之时多选
      this.isSelectGoodsRadio = SchemeId === "1" ? true : false;
      //当组合优惠方案时 活动明细的字段初始化
      if (SchemeId === "10") {
        this.$set(this.form, "comboHead", {
          comboHeadName: undefined, //组合名称
          discountType: "1", //优惠类型(1特价,2优惠,3折扣)
          discountValue: undefined, //优惠值（特价、金额、折扣）
        });
      }
      //当固定组合方案/动态组合方案/组合优惠方案时 组合商品字段初始化
      if (SchemeId === "2" || SchemeId === "3" || SchemeId === "10") {
        this.$set(this.form, "comboItem", [
          //组合商品数组
          {
            comboGoodsItem: [{}], //商品表格数组  初始化数量为0
            comboName: undefined, //组合名称
            comboPrice: undefined, //组合价格
            arbitrarily: "1", //任意方式
            comboSelectType: "0", //必选/可选类型
            comboSelectQty: undefined, //任意数量
            discount: undefined, //折扣
          },
        ]);
        //当是动态组合方案时, 商品表格数据 不需要初始化第一行
        if (SchemeId === "3") {
          this.$set(this.form.comboItem[0], "comboGoodsItem", []);
        }
      }
      //当买满赠送方案时,赠送的商品/礼券字段初始化
      if (SchemeId === "8") {
        this.$set(this.form, "buyLargessType", "0"); //买赠方式
        this.$set(this.form, "buyLargessQty", undefined); //任选赠送数量
        this.$set(this.form, "optionalGoodsItem", []); //赠送的商品任选表格数组(页面使用，最后提交要判断买赠方式后，以goodsLargessItem提交)
        this.$set(this.form, "fixedGoodsItem", [{}]); //赠送的商品固定赠品表格数组(页面使用，最后提交要判断买赠方式后，以goodsLargessItem提交)
        this.$set(this.form, "goodsLargessItem", [{}]); //赠送的商品表格数组
        this.$set(this.form, "couponLargessItem", [{}]); //赠送礼券(优惠券)
        this.$set(this.form, "ruleLargessItem", [
          //买一赠一规则
          {
            largessRule: "0", //赠送规则
            addMoney: "0", //加购金额
            discountRate: "", //折扣比例
          },
        ]);
      }
      //当数量折扣方案/金额折扣方案/金额满减方案/买满赠送方案/充值免单方案/组合优惠方案 活动明细的字段初始化
      if (
        SchemeId === "5" ||
        SchemeId === "6" ||
        SchemeId === "7" ||
        SchemeId === "8" ||
        SchemeId === "9" ||
        SchemeId === "10" ||
        SchemeId === "11" ||
        SchemeId === "12"
      ) {
        this.$set(this.form, "buyFullLargessType", "0"); //买满方式
        this.$set(this.form, "buyFullMoneyType", "1"); //买满金额类型
        this.$set(this.form, "buyFullQtyType", "1"); //买满数量类型
        this.$set(this.form, "buyFullItemMoneyType", "1"); //买满金额类型
        this.$set(this.form, "buyFullItem", [
          //买满件数/买满金额明细
          {
            buyReserveDay: undefined, //提前预订天数
            buyFullValue: undefined, //买满金额/买满数量
            giveValue: undefined, //赠送金额/减免金额/赠送数量
            discountType: "0", //优惠方式
            fillMultiple: undefined, //充值倍数
          },
        ]);
      }
      if (SchemeId === "12") {
        this.$set(this.form, "buyFullLargessType", "0"); //买满方式
        this.$set(this.form, "buyFullMoneyType", "2"); //买满金额类型
        this.$set(this.form, "buyFullQtyType", "2"); //买满数量类型
        this.$set(this.form, "isAllowOfflineShop", true); //买满数量类型
        this.$set(this.form, "buyFullItem", [
          //买满件数/买满金额明细
          {
            buyReserveDay: undefined, //提前预订天数
            buyFullValue: undefined, //买满金额/买满数量
            giveValue: undefined, //赠送金额/减免金额/赠送数量
            discountType: "0", //优惠方式
            fillMultiple: undefined, //充值倍数
          },
        ]);
        this.$set(this.form, "goodsLargessItem", [{}]);
        this.$set(this.form, "AllowOfflineShop", ["0"]);
      }
      if (SchemeId === "9") {
        this.$set(this.form, "buyFullMoneyType", "2"); //买满金额类型
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.special-scheme-detail {
  // padding-top: 34px;
  // padding-bottom: 10px;
  padding: 34px 10px 0 10px;
  min-height: 830px;
  background-color: #eaeaea;
  //表单文本显示(向左对齐)
  ::v-deep.el-form-item__label {
    text-align: left !important;
  }
  ::v-deep .el-checkbox {
    color: #333333;
  }
  ::v-deep .el-radio {
    color: #333333;
  }

  //基本信息
  .basicInfo {
    // padding: 0 20px;
    // padding-bottom: 10px;
    padding: 10px 20px 0 20px;
    //指定日期
    .assign {
      width: 680px;
      border: 1px solid #e6e6e6;
      padding: 20px;
      padding-right: 10px;

      .el-checkbox {
        margin-right: 10px;
      }
    }

    .scheme {
      .el-form-item {
        width: 500px;
        margin-right: 10px;
        //日期
        .el-date-editor {
          width: 420px;
        }
      }
    }
  }

  //活动规则
  .activity {
    padding: 0 20px;
    padding-bottom: 10px;
    //每一行的间距
    .el-form-item {
      margin-bottom: 10px;
    }

    //会员生日下拉框宽度
    .selectVipBD {
      margin-left: 5px;
      width: 140px;
    }

    .selectShop,
    .selectVip {
      .shopBtn,
      .vipBtn {
        margin-bottom: 10px;
      }
    }
  }

  //表格行高度/*
  /* ::v-deep.el-table__cell{
    padding: 0 !important;
  }  */
  //活动规则/参与活动的商品
  .activityDetail,
  .goods1,
  .goods3,
  .giveGoods,
  .goods4,
  .remark {
    padding: 0 20px;
    padding-bottom: 20px;
    //表格区域编号单元格
    ::v-deep.el-form-item {
      margin-bottom: 0;
    }

    ::v-deep.el-form-item__content {
      margin-left: 0 !important;
    }
  }

  //数量折扣方案 活动明细表格
  .activityDetail {
    width: 800px;
    //组合优惠方案-优惠方式
    .top {
      margin-top: 10px;
    }
    .discuountWayList {
      .item {
        width: 400px;
        margin: 5px 0;
        height: 38px;

        ::v-deep.el-form-item__content {
          margin-left: 60px !important;
        }

        ::v-deep.el-form-item__label {
          padding: 0 !important;
        }

        //文本框
        .el-input {
          width: 50%;
          margin-left: 5px;
        }
      }
    }

    .table {
      margin-top: 10px;
    }

    //折扣百分比输入框
    .percentage {
      width: 120px;
    }
  }

  //数量特价方案
  .goods2 {
    margin: 10px;
    // padding: 10px;
    border: 1px solid #dddddd;
    height: 500px;
    display: flex;

    .left {
      width: 260px;
      border-right: 1px solid #dddddd;
      //顶部按钮
      .topLeft {
        text-align: center;

        .el-button {
          color: #017ee1;
        }
      }

      //商品优惠列表
      .list {
        display: flex;
        flex-wrap: wrap;
        height: 100%;

        .item {
          width: 100%;
          height: 150px;
          font-size: 14px;
          visibility: visible;
          border: 1px solid #e6e6e6;
          padding: 5px;
          //顶部区域(删除图标)
          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .name {
            margin: 5px 0;
            height: 15px;
          }

          //折扣列表区域
          .discountList {
            display: flex;
            height: 90px;
            border: 1px solid #e6e6e6;
            overflow-x: hidden;
            margin-bottom: 10px;
            padding: 10px 20px;
            text-align: center;
            color: #999999;

            .discountItem {
              height: 23px;
              line-height: 23px;
            }
          }
        }

        //充值规则列表获得焦点
        .focusItem {
          position: relative;
          width: 100%;
          height: 150px;
          font-size: 14px;
          visibility: visible;
          border: 1px solid #017ee1;

          &::before {
            position: absolute;
            content: "";
            right: 0;
            bottom: 0;
            border-right: 13px solid #2386ee;
            border-left: 13px solid transparent;
            border-top: 11px solid transparent;
            border-bottom: 11px solid #2386ee;
          }

          &::after {
            position: absolute;
            content: "";
            width: 10px;
            height: 6px;
            background-color: transparent;
            right: 3px;
            bottom: 5px;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }

          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }

    .right {
      width: 1348px;
      background-color: #f2f2f2;

      .topRight {
        display: flex;
        align-items: center;
        .el-form-item {
          margin: 0 5px;

          ::v-deep.el-form-item__label {
            width: 70px !important;
            padding-right: 0 !important;
          }

          ::v-deep.el-form-item__content {
            margin-left: 70px !important;
          }
        }
      }

      .discountDetailList {
        padding: 20px 30px;
        display: flex;

        .leftLabel {
          width: 100px;
        }

        //折扣规则列表
        .rightCentent {
          .item {
            width: 100%;
            display: flex;
            margin-bottom: 5px;

            .el-form-item {
              margin-right: 5px;
              //规则输入框从右开始
              ::v-deep.el-input__inner {
                text-align: right;
              }
            }

            ::v-deep.el-form-item__label {
              width: 35px !important;
              padding-right: 0 !important;
            }

            ::v-deep.el-form-item__content {
              display: flex !important;
              margin-left: 35px !important;
            }

            .del {
              margin-top: 10px;
              margin-left: 10px;
              font-size: 12px;
              color: #017ee1;
              cursor: pointer;
            }
          }

          .ruleAdd {
            text-align: right;
            margin-right: 48px;
            margin-top: 5px;
          }
        }
      }
    }

    //顶部
    .topLeft,
    .topRight {
      height: 50px;
      border-bottom: 1px solid #dddddd;
      background-color: #fff;
      line-height: 50px;
    }

    .main {
      height: 450px;
    }
  }

  //固定组合方案/动态组合方案
  .goods3 {
    //数组
    .list {
      //每一项
      .item {
        margin: 5px 0;
        //顶部左边区域
        .left {
          ::v-deep.el-form-item__content {
            display: flex !important;
          }

          ::v-deep.el-input {
            width: 160px !important;
          }

          //动态组合方案任意件数
          .el-radio {
            margin-right: 10px;
            margin-left: 20px;
          }

          .num {
            ::v-deep.el-form-item__content {
              width: 100px !important;
            }
          }
        }

        //商品表格区域
        .table {
          margin-top: 15px;
          //使表格校验时显示，校验通过时隐藏
          ::v-deep .el-form-item {
            padding: 0;
            margin: 0;
          }

          ::v-deep .el-form-item__error {
            position: static;
          }
        }
      }
    }
  }

  //折扣优惠方案
  .goods1,
  .giveGoods {
    //表格区域
    .table {
      .btn {
        margin: 10px 0;
      }
    }
  }

  //买满赠送方案
  .goods4 {
    //任选N件
    .optional {
      .btn {
        margin-bottom: 10px;
      }
    }

    //赠送礼券/固定赠品
    .sendCoupon,
    .flxedGiftTable {
      //使表格校验时显示，校验通过时隐藏
      ::v-deep .el-form-item {
        padding: 0;
        margin: 0;
      }

      ::v-deep .el-form-item__error {
        position: static;
      }
    }

    //买一赠一区域
    .salesWith {
      .item {
        width: 620px;
        margin: 5px 0;
        height: 38px;

        ::v-deep.el-form-item__content {
          margin-left: 80px !important;
        }

        ::v-deep.el-form-item__label {
          padding: 0 !important;
        }

        //文本框
        .el-input {
          width: 50%;
          margin-left: 5px;
        }
      }
    }
  }

  //备注
  .remark {
    padding: 10px 20px 20px 20px;
    //方案备注
    .billRemarkArea {
      width: 800px;
    }
  }

  //提交按钮
  .subBtn {
    text-align: center;
  }
}
</style>
