<template>
  <!-- 表格 -->
  <div class="tableBox">
    <div class="table">
      <el-table
        class="table"
        ref="dragTable"
        :data="tableData"
        row-key="lineId"
        border
        max-height="300"
      >
        <el-table-column
          label="序号"
          type="index"
          width="80"
          class-name="allowDrag"
          align="center"
        />
        <el-table-column align="center" label="操作" width="120" v-if="!disabled">
          <template v-slot="scope">
            <i
              @click="tableAddRow"
              class="el-icon-circle-plus operatePush"
              v-if="isAddTableRowBtn"
            ></i>
            <i
              @click="tableDelRow(scope.row, scope.$index)"
              class="el-icon-remove operateDel"
            ></i>
          </template>
        </el-table-column>
        <el-table-column
          label="类型"
          align="center"
          prop="lineType"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <dict-tag
              :options="dict.type.vip_applicable_commodities_appoint_type"
              :value="scope.row.lineType"
            />
          </template>
        </el-table-column>
        <el-table-column label="编码" width="260" align="center" prop="lineNo">
        </el-table-column>
        <el-table-column
          label="名称"
          align="center"
          prop="lineName"
          show-overflow-tooltip
        >
        </el-table-column>
        <!-- 数量插槽 -->
        <slot name="amount"></slot>
        <el-table-column label="单位" align="center" width="120">
          <template slot-scope="scope" v-if="scope.row.lineType == 1">
            <el-form-item>
              <el-select
                v-model="scope.row.unitId"
                @change="changeUnitPrice(scope.row, scope.row.unitId, scope.row.units)"
                :disabled="disabled"
              >
                <el-option
                  v-for="item in scope.row.units"
                  :key="item.unitId"
                  :label="item.unitName"
                  :value="item.unitId"
                />
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          label="规格"
          align="center"
          width="200"
          prop="goodsSpec"
        ></el-table-column>
        <el-table-column label="售价(元)" align="center" width="200" prop="salePrice">
        </el-table-column>
        <!-- 价格插槽 -->
        <slot name="discountPrice"></slot>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "T-Table",
  dicts: ["vip_applicable_commodities_appoint_type"], //商品类型
  props: {
    //表格数据
    tableData: {
      type: Array,
    },
    //操作里是否有增加一行按钮
    isAddTableRowBtn: {
      type: Boolean,
      default: false,
    },
    //操作按钮隐藏状态
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    //表格删除行
    tableDelRow(row, index) {
      row.rowIndex = index;
      this.$emit("delTableRow", row);
    },
    //表格增加行
    tableAddRow() {
      this.$emit("addTableRow", "");
    },
    //单位改变售价跟着变 row当前表格行 unitId单位Id units单位数组
    changeUnitPrice(row, unitId, units) {
      units.forEach((v) => {
        if (v.unitId === unitId) {
          row.salePrice = v.salePrice;
          row.goodsSpec = v.goodsSpec;
        }
      });
    },
  },
};
</script>

<style></style>
